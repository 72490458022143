import { ContactModal } from "@components/jobs";
import { TextLabel } from "@components/textlabel";
import { Button } from "@mui/material";
import { stripHtml } from "@utils";
import parse from "html-react-parser";
import { useState } from "react";
import { ImOffice } from "react-icons/im";
import { IoLocationSharp } from "react-icons/io5";
import { MdWork } from "react-icons/md";

const JobPublicGeneralInfo = (props: any) => {
  const job = props.job;
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <ContactModal open={openModal} handleClose={handleClose} job={job} />
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column  w-100 mb-2 job-public-name-info gap-2 ">
          <div className="job-public-name pb-3">
            <h4 className="  m-0">{job?.name}</h4>
            <div className="ml-auto">
              <Button
                className="hire-button bend-primary"
                onClick={handleOpen}
                variant="contained"
              >
                Apply
              </Button>
            </div>
          </div>
          <div className=" d-flex flex-row justify-content-start gap-5">
            {job?.location ? (
              <div className="d-flex  align-items-center gap-2  job-public-location">
                <IoLocationSharp size={"1.4em"} />
                <h6 className="text-muted m-0">{job?.location}</h6>
              </div>
            ) : (
              ""
            )}
            {job?.work_location ? (
              <div className="d-flex  align-items-center  gap-2 job-public-location">
                <ImOffice size={"1.4em"} />
                <h6 className="text-muted m-0">{job?.work_location}</h6>
              </div>
            ) : (
              ""
            )}
            {job?.contract_type ? (
              <div className="d-flex align-items-center  gap-2 job-public-location">
                <MdWork size={"1.4em"} />
                <h6 className="text-muted m-0">{job?.contract_type}</h6>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {stripHtml(job?.description) ? (
          <div className="d-flex flex-column job-public-description">
            {parse(job?.description)}
          </div>
        ) : (
          ""
        )}
        {stripHtml(job?.requirements) ? (
          <div className="  d-flex job-public-description  flex-column">
            <TextLabel>Requirements</TextLabel>
            <div>{parse(job?.requirements)}</div>
          </div>
        ) : (
          ""
        )}
        {stripHtml(job?.benefits) ? (
          <div className="  d-flex job-public-description  flex-column">
            <TextLabel>Benefits</TextLabel>
            <div>{parse(job?.benefits)}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export { JobPublicGeneralInfo };
