import { defaultTalent } from "@const";
import { EmptyTalent, ITalentState, Talent, TalentStatus } from "@interfaces";
import { createContext, useContext, useReducer } from "react";
import { useForm } from "react-hook-form";

type Action =
  | { type: "SET_TALENT"; payload: Talent | EmptyTalent }
  | { type: "SET_STATUS"; payload: TalentStatus }
  | { type: "SET_ORIGINAL_TALENT"; payload: Talent | EmptyTalent };

const reducer = (state: ITalentState, action: Action): ITalentState => {
  switch (action.type) {
    case "SET_TALENT":
      return { ...state, talent: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_ORIGINAL_TALENT":
      return {
        ...state,
        originalTalent: action.payload,
        talent: action.payload,
      };

    default:
      return state;
  }
};

export const TalentStoreContext = createContext<{
  state: ITalentState;
  dispatch: React.Dispatch<Action>;
}>({
  state: {
    talent: null,
    originalTalent: null,
    status: "view",
    form: null,
  },
  dispatch: () => {},
});

export const useTalentStore = () => {
  const context = useContext(TalentStoreContext);
  if (context === undefined) {
    throw new Error("useTalentStore must be used within a TalentStoreProvider");
  }
  return context;
};

type TalentContextProviderProps = {
  children: React.ReactNode;
};

export const TalentStoreProvider: React.FC<TalentContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    originalTalent: defaultTalent,
    talent: defaultTalent,
    status: "pending",
    form: useForm(),
  });

  return (
    <TalentStoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TalentStoreContext.Provider>
  );
};
