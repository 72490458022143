import { ClientTextField } from "@components/clients";
import { useClientStore } from "@store";
import { MDBCard } from "mdb-react-ui-kit";
import { forwardRef } from "react";
import { ClientShortDescription } from "./ClientShortDescription";

const ClientGeneralInfo = forwardRef((props: any, ref: any) => {
  const { clientState: state, clientDispatch: dispatch } = useClientStore();

  return (
    <MDBCard className="general-info ">
      <div className="  general-info-grid">
        <ClientTextField field={["name"]} label="Client name" required />
        <ClientTextField field={["country"]} label="Country" />
        <ClientTextField field={["email"]} label="Contact" />
        <ClientTextField
          field={["city", "address"]}
          placeholder={["City", "Address"]}
          label="Location"
        />
      </div>

      <ClientShortDescription />
    </MDBCard>
  );
});
export { ClientGeneralInfo };
