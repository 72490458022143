import { BEND_PRIMARY } from "@const";
import { Job } from "@interfaces";
import { Box, Button, Modal, TextField } from "@mui/material";
import "@styles/contact.modal.css";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegFaceSadTear, FaRegFaceSmileBeam } from "react-icons/fa6";
import { JobCVForm } from "./JobCVForm";

type ContactModalProps = {
  job: Job;
  open: boolean;
  handleClose: () => void;
};

const ContactModal = ({ job, open, handleClose }: ContactModalProps) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState<any>(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [submitMessage, setSubmitMessage] = useState<string | null>("");
  const [submitStatus, setSubmitStatus] = useState<string | null>(null);

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    register("cv_file", { required: "Field required", validate: isFilePDF });
  }, []);

  const isFilePDF = (file: any) => {
    return file?.type === "application/pdf" || "CV should be in PDF format";
  };

  const onCVFileChange = (event: any) => {
    setFile(event.target.files[0]);
    setValue("cv_file", event.target.files[0]);
  };

  const handleHire = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/public/jobs/apply`,
        serialize({
          job_id: job?.id,
          job_name: job?.name,
          name,
          email,
          file,
        })
      )
      .then((res) => {
        setSubmitStatus("success");
        setSubmitMessage(
          "Thank you for your interest! We will contact you shortly."
        );
      })
      .catch((err) => {
        setSubmitStatus("error");
        setSubmitMessage("Something went wrong. Please try again later.");
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setName("");
        setEmail("");
        setFile(null);
        setSubmitMessage(null);
        setSubmitStatus(null);
        handleClose();
      }}
      className="modal"
      style={{ backdropFilter: "blur(4px)" }}
    >
      <div className="paper ">
        {submitStatus === null ? (
          <form className="form" onSubmit={handleSubmit(handleHire)}>
            <TextField
              {...register("name", { required: true })}
              error={Boolean(errors?.name)}
              helperText={Boolean(errors?.name) ? "Field required" : ""}
              size="small"
              label="Name"
              variant="outlined"
              value={name}
              onChange={handleNameChange}
            />
            <TextField
              {...register("email", { required: true })}
              error={Boolean(errors?.email)}
              helperText={Boolean(errors?.email) ? "Field required" : ""}
              size="small"
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
            <JobCVForm cvFile={{ cvFile: file, setCvFile: onCVFileChange }} />
            {Boolean(errors?.cv_file) ? (
              <span className="error-msg">
                <>{errors?.cv_file?.message}</>
              </span>
            ) : (
              ""
            )}
            <Box className="">
              <hr />
            </Box>
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ height: "38px" }}
                className="bend-primary"
              >
                Send
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ height: "38px" }}
                className="bend-primary-inverted"
              >
                Cancel
              </Button>
            </div>
          </form>
        ) : (
          <div
            className="d-flex flex-column gap-3 align-items-center"
            style={{ color: submitStatus === "success" ? BEND_PRIMARY : "red" }}
          >
            {submitStatus === "success" ? (
              <FaRegFaceSmileBeam size={"2em"} />
            ) : (
              <FaRegFaceSadTear size={"2em"} />
            )}
            <div>{submitMessage}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export { ContactModal };
