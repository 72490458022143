import { EmptyJob } from "@interfaces";

export const defaultJob: EmptyJob = {
  title: "",
  description: "",
  requirements: "",
  benefits: "",
  type: "",
  location: "",
  experience: "",
  client: null,
  internal_notes: "",
  status: "Open",
};
