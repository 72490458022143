import { TalentActivity } from "@interfaces";
import { Button, TextField } from "@mui/material";
import { useTalentStore } from "@store";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { TalentEventItem } from "./TalentEventItem";

interface IFormValues {
  comment: string;
}

const TalentHistory = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const logActivity = props.logActivity;
  const [activities, setActivities] = useState<any[]>(props.talentActivity);
  const id = state.talent?.id;
  const [comment, setComment] = useState<string>("");
  const formRef = useRef<any>(null);
  const [inputRows, setInputRows] = useState<number>(1);
  const { register, handleSubmit, formState } = useForm<IFormValues>();

  useEffect(() => {
    setActivities(props.talentActivity);
  }, [props.talentActivity]);

  if (props.addMode) {
    return null;
  }
  return (
    <MDBCard className="border-0">
      <MDBCardHeader>Activity</MDBCardHeader>
      <MDBCardBody className="p-0">
        <div className="px-3 py-1">
          <form
            onFocus={() => {
              setInputRows(3);
            }}
            ref={formRef}
            onSubmit={handleSubmit((comment) => {
              logActivity("comment_activity", comment);
              setComment("");
            })}
          >
            <div>
              <TextField
                {...register("comment", {
                  required: true,
                })}
                multiline
                rows={inputRows}
                error={Boolean(formState.errors?.comment)}
                helperText={
                  Boolean(formState.errors?.comment) ? "Field required" : ""
                }
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                className="py-2 d-flex flex-grow-1"
                size="small"
                placeholder="Add new comment"
              ></TextField>
              {inputRows > 1 ? (
                <div className="d-flex gap-2 pb-2">
                  <Button size="small" variant="outlined" type="submit">
                    Submit
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setComment("");
                      setInputRows(1);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
        {activities?.length ? (
          <div className="talent-timeline-items">
            {activities?.map((activity: TalentActivity, index: number) => {
              return (
                <div key={index}>
                  <TalentEventItem event={activity} />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </MDBCardBody>
    </MDBCard>
  );
};
export default TalentHistory;
