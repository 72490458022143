import { wrapLinksInAnchorTags } from "@utils";
import parse from "html-react-parser";
import { ReactElement } from "react";

export const formatProjectResponsibilities = (
  responsibilities: string
): ReactElement => {
  const formattedResponsibilities = responsibilities
    ? responsibilities
        .split(/\r?\n/)
        .map((responsibility: string, i: number) => {
          const trimmedResponsibility = responsibility.trim();
          return trimmedResponsibility ? (
            <li key={i}>
              {parse(wrapLinksInAnchorTags(trimmedResponsibility))}
            </li>
          ) : null;
        })
        .filter(Boolean)
    : [];
  return <ul>{formattedResponsibilities}</ul>;
};
