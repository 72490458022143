import { ClientsTable } from "@components/clients";
import { Button } from "@mui/material";
import "@styles/clients.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  const fetchClients = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/clients`).then((response) => {
      setClients(response.data);
    });
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className="clients-container">
      <div className="top">
        <div>
          <h3>Clients</h3>
        </div>
        <div>
          <Link to={"/clients/add"}>
            <Button className="button" size="small" variant="contained">
              Add client
            </Button>
          </Link>
        </div>
      </div>

      <ClientsTable total={0} clients={clients} />
    </div>
  );
};
export { Clients };
