import React from "react";

type TextLabelProps = {
  internal?: boolean;
  children: React.ReactNode;
};

const TextLabel = ({ internal, children }: TextLabelProps) => {
  return (
    <div className="talent-label d-flex flex-row align-items-center gap-2">
      {children}
      <div
        style={{
          color: internal ? "red" : "orange",
          fontSize: 12,
          fontStyle: "italic",
          fontWeight: 300,
        }}
      >
        {internal === true ? "private" : internal !== undefined ? "public" : ""}
      </div>
    </div>
  );
};

export { TextLabel };
