import { TextLabel } from "@components/textlabel";
import { TextField } from "@mui/material";
import { useClientStore } from "@store";
import parse from "html-react-parser";

const ClientShortDescription = (props: any) => {
  const { clientState: state, clientDispatch: dispatch } = useClientStore();

  if (
    state.status !== "edit" &&
    !state.client?.contact_info &&
    !state.client?.description
  )
    return null;

  return (
    <>
      {state.client?.contact_info || state.status === "edit" ? (
        <div>
          <TextLabel>Contact Info</TextLabel>
          {state.status === "edit" ? (
            <div>
              <TextField
                className="w-100"
                multiline
                minRows={2}
                value={state.client?.contact_info || ""}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CLIENT",
                    payload: {
                      ...state.client,
                      contact_info: e.target.value.length ? e.target.value : "",
                    },
                  })
                }
              />
            </div>
          ) : (
            <div>{parse(state.client?.contact_info!)}</div>
          )}
        </div>
      ) : (
        ""
      )}

      {state.client?.description || state.status === "edit" ? (
        <div>
          <TextLabel>Description</TextLabel>
          {state.status === "edit" ? (
            <div>
              <TextField
                InputLabelProps={{ shrink: true }}
                className="w-100"
                multiline
                minRows={2}
                value={state.client?.description || ""}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CLIENT",
                    payload: {
                      ...state.client,
                      description: e.target.value.length ? e.target.value : "",
                    },
                  })
                }
              />
            </div>
          ) : (
            <div>{parse(state.client?.description!)}</div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export { ClientShortDescription };
