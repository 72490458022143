import {
  IItemsState,
  IItemsStateFilters,
  IPropsSelectGroup,
  RequestStatus,
  Talent,
} from "@interfaces";
import React, { createContext, useMemo, useReducer } from "react";
import { useLocation } from "react-router-dom";

type Action =
  | { type: "SET_ITEMS"; payload: Talent[] }
  | { type: "SET_FILTERS"; payload: IItemsStateFilters }
  | { type: "SET_STATUS"; payload: RequestStatus };

const reducer = (state: IItemsState, action: Action): IItemsState => {
  switch (action.type) {
    case "SET_ITEMS":
      return { ...state, items: action.payload };
    case "SET_FILTERS":
      return { ...state, filters: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

const ItemsContext = createContext<{
  state: IItemsState;
  dispatch: React.Dispatch<Action>;
}>({
  state: {
    items: [],
    status: "idle",
    filters: null,
  },
  dispatch: () => {},
});

const useFilterContext = () => {
  const context = React.useContext(ItemsContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a ItemsProvider");
  }
  return context;
};

type FilterContextProviderProps = {
  children: React.ReactNode;
};

const FilterContextProvider: React.FC<FilterContextProviderProps> = ({
  children,
}) => {
  const location = useLocation();

  const getInitialFilters = useMemo((): IItemsStateFilters => {
    const params = new URLSearchParams(location.search);

    const getParam = (key: string) => {
      const value = (params.get(key));

      return value ? decodeURIComponent(value) : null;
    }

    return {
      page: parseInt(getParam("page")!) || 0,
      take: parseInt(getParam("take")!) || 10,
      query: getParam("query") || "",
      seniority: getParam("seniority") || "",
      type: getParam("type") || "",
      status: parseInt(getParam("status")!) || null,
      technologies: JSON.parse(getParam("technologies")!) || [],
      english: getParam("english") || "",
      sort: getParam("sort") || "",
      order: getParam("order") || "",
      rejected: JSON.parse(getParam("rejected")!) || false,
      creation_method: (getParam('creation_method') ?? null) as NonNullable<IPropsSelectGroup['filters']>['creation_method'],
    };
  }, [location]);
  const [state, dispatch] = useReducer(reducer, {
    items: [],
    status: "idle",
    filters: getInitialFilters,
  });

  return (
    <ItemsContext.Provider value={{ state, dispatch }}>
      {children}
    </ItemsContext.Provider>
  );
};

export { useFilterContext, FilterContextProvider };
