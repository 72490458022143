import { Project } from "@interfaces";
import dayjs, { Dayjs } from "dayjs";

export function smartDate(inputDate: any) {
  const date = dayjs(inputDate);
  const now = dayjs();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (now.isSame(date, "day")) {
    return "Today " + date.format("HH:mm");
  } else if (now.subtract(1, "day").isSame(date, "day")) {
    return "Yesterday " + date.format("HH:mm");
  } else if (now.diff(date, "day") < 7) {
    return daysOfWeek[date.day()] + " " + date.format("HH:mm");
  } else {
    return date.format("DD/MM/YYYY ");
  }
}

export const calcDuration = (
  from: string | Dayjs,
  to: string | Dayjs,
  ongoing: boolean
) => {
  const from_date = dayjs(from);
  const to_date = ongoing ? dayjs() : dayjs(to);
  const durationMonths = to_date.diff(from_date, "month");
  const durationYears = to_date.diff(from_date, "year");
  const totalMonths = durationMonths % 12;
  return `${
    durationYears
      ? durationYears > 1
        ? durationYears + " years"
        : durationYears + " year "
      : ""
  } ${
    totalMonths
      ? totalMonths > 1
        ? totalMonths + " months"
        : totalMonths + " month "
      : ""
  } `;
};

export const calcTotalDuration = (projects: Project[]) => {
  const sortedProjects = projects.sort(
    (a, b) => dayjs(a.from).valueOf() - dayjs(b.from).valueOf()
  );
  const mergedProjects = [];

  for (let i = 0; i < sortedProjects.length; i++) {
    const currentProject = sortedProjects[i];
    const currentFrom = dayjs(currentProject.from);
    const currentTo = currentProject.ongoing
      ? dayjs()
      : dayjs(currentProject.to);
    let mergedProject = {
      from: currentFrom,
      to: currentTo,
    };

    for (let j = i + 1; j < sortedProjects.length; j++) {
      const nextProject = sortedProjects[j];
      const nextFrom = dayjs(nextProject.from);
      const nextTo = nextProject.ongoing ? dayjs() : dayjs(nextProject.to);

      if (nextFrom.isBefore(currentTo)) {
        mergedProject.to = currentTo.isBefore(nextTo) ? nextTo : currentTo;
        i++;
      } else {
        break;
      }
    }

    mergedProjects.push(mergedProject);
  }

  const totalDuration = mergedProjects.reduce((acc, project) => {
    const durationMonths = project.to.diff(project.from, "month");
    return acc + (durationMonths ? durationMonths : 1);
  }, 0);

  const totalYears = Math.floor(totalDuration / 12);
  const totalMonths = totalDuration % 12;

  return `${
    totalYears
      ? totalYears > 1
        ? totalYears + " years"
        : totalYears + " year "
      : ""
  } ${
    totalMonths
      ? totalMonths > 1
        ? totalMonths + " months"
        : totalMonths + " month "
      : ""
  } `;
};
