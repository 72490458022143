import { Navbar } from "@components/navbar";
import "@config";
import { MainRouter } from "@routers";
// import eruda from "eruda";
// eruda.init();

const Main = () => {
  return (
    <div className="app-root">
      <Navbar />
      <MainRouter />
    </div>
  );
};

export { Main };
