import { TalentStatus } from "@components/talents";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useTalentStore } from "@store";
import { smartDate } from "@utils";
import dayjs from "dayjs";
import { MDBCard } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useGeneratePDFBlobURL } from "src/hooks";

const TalentHeaderInfo = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const { isGenerated, downloadPdf } = useGeneratePDFBlobURL();
  const submitChanges = props.submitChanges;

  const observer = new IntersectionObserver(
    ([e]) => e.target.toggleAttribute("stuck", e.intersectionRatio < 1),
    {
      threshold: [1],
    }
  );
  useEffect(() => {
    observer.observe(document.querySelector(".talent-sticky")!);
  }, []);

  return (
    <div className="talent-sticky">
      <div className="d-flex justify-content-between ">
        <Button
          className="talent-back-button "
          size="small"
          onClick={props.navigateBack}
        >
          <div className="d-flex align-items-center">← Back to talents</div>
        </Button>
        <>
          {state.talent?.updated_at ? (
            <div className="text-primary">
              Modified at: {smartDate(dayjs(state.talent?.updated_at))}
            </div>
          ) : (
            ""
          )}
        </>
      </div>
      {!props.addMode ? (
        <MDBCard className="talent-header">
          <div className="talent-public-link">
            <Link
              target="_blank"
              to={`//${process.env.REACT_APP_URL?.split(
                "://"
              )[1]}/public/talents/${state.talent?.external_id}`}
            >
              <h4 className=" d-flex m-0 align-items-center gap-2">
                {state.talent?.name} <FiExternalLink />
              </h4>
            </Link>
          </div>

          <div className="d-flex flex-row align-items-center gap-4">
            <LoadingButton
              className="text-nowrap background-bend-primary-color"
              loading={isGenerated}
              variant="contained"
              onClick={() => {
                downloadPdf(
                  `${
                    state.talent!.name.split(" ")[0] +
                    " " +
                    state.talent!.name.split(" ")[1][0]
                  } Bend CV.pdf`,
                  state.talent?.id
                );
              }}
            >
              Generate PDF
            </LoadingButton>
            {state.status !== "edit" ? (
              <Button
                className="text-nowrap background-bend-primary-color"
                variant="contained"
                onClick={() =>
                  dispatch({ type: "SET_STATUS", payload: "edit" })
                }
              >
                Edit profile
              </Button>
            ) : (
              <div className="d-flex gap-2 flex-row">
                <Button
                  className="text-nowrap background-bend-primary-color"
                  variant="contained"
                  onClick={() => props.formRef.current?.requestSubmit()}
                >
                  Save
                </Button>
                <Button
                  className="text-nowrap background-bend-primary-color"
                  variant="contained"
                  onClick={() => {
                    dispatch({ type: "SET_STATUS", payload: "view" });
                    dispatch({
                      type: "SET_TALENT",
                      payload: state.originalTalent!,
                    });
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}

            <TalentStatus
              submitChanges={submitChanges}
              logActivity={props.logActivity}
            />
          </div>
        </MDBCard>
      ) : (
        ""
      )}
    </div>
  );
};
export default TalentHeaderInfo;
