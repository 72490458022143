import { BEND_PRIMARY } from "@const";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTalentStore } from "@store";
import { AiOutlineFilePdf } from "react-icons/ai";

const JobCVForm = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const addMode = props.addMode;
  // const submitChanges = props.submitChanges;
  const { cvFile, setCvFile } = props.cvFile;

  const removePathMetadata = (url: string) => {
    if (!url) return null;
    const name = url?.replace(/\\+/g, "/")?.split("/")[1]?.split("_") || [""];
    name?.shift();
    return name?.join("_");
  };

  return (
    <div className="d-flex flex-row gap-1 mx-2">
      <div className="d-flex align-items-center gap-2">
        <AiOutlineFilePdf size={"1.4em"} color={BEND_PRIMARY} />{" "}
        {state.talent?.cv_file_url ? (
          removePathMetadata(state.talent?.cv_file_url)
        ) : state.talent?.cv_file?.name ? (
          <i className="text-muted">{state.talent?.cv_file?.name}</i>
        ) : (
          ""
        )}
      </div>
      <div
        className="d-flex align-items-center"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {cvFile ? cvFile?.name : ""}
        </div>
        <div className="text-nowrap">
          <LoadingButton
            size="small"
            loading={props.uploadLoading}
            variant="text"
            component="label"
            style={{ textTransform: "none", color: BEND_PRIMARY }}
          >
            Upload your CV
            <input
              onChange={(e) => {
                setCvFile(e);
              }}
              type="file"
              hidden
            />
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export { JobCVForm };
