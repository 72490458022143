import { BEND_PRIMARY } from "@const";
import { Button } from "@mui/material";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const TalentPublicCTA = () => {
  return (
    <>
      <MDBCard className=" cta-item border-0 d-flex flex-column justify-content-center book-cta">
        <MDBCardBody>
          <h5 className="d-flex justify-content-center font-weight-bold">
            Ready to take the next step?
          </h5>
          <div className="pt-2 text-center">
            We help companies like yours find the right talent for your needs.
            Let us help you build a team today.
          </div>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="cta-item border-0 d-flex flex-column justify-content-center md-6 p-2  py-0 expect-cta">
        <MDBCardBody className="d-flex  flex-column align-items-center">
          <h6
            className="d-flex justify-content-start font-weight-bold pb-2"
            style={{ color: BEND_PRIMARY }}
          >
            WHAT TO EXPECT
          </h6>
          <div className=" d-flex flex-column align-items-center">
            <div className="font-weight-bold mb-2">A session with Bend CEO</div>
            <ul className="p-0">
              <li>Discuss your recruitment needs and goals</li>
              <li>Learn about our recruitment process</li>
              <li>
                Get answers to any question you have about our services, pricing
                and timeline for recruitment
              </li>
              <li>Walk away with clear understanding of how we can help you</li>
            </ul>
            <Button
              size="medium"
              className="book-button bend-primary"
              variant="contained"
            >
              <Link
                target="_blank"
                to={
                  "//calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0lWrKIjjMunGb4qwebQXw2nUGeY5adqU_Wjlfzz9bDerX8FdfP_NWzxOLFb9MXeMLYwFdaJh3c"
                }
              >
                <div className="">Book a call</div>
              </Link>
            </Button>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
export { TalentPublicCTA };
