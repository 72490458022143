import { defaultClient } from "@const";
import {
  Client,
  ClientFilters,
  ClientState,
  ClientStatus,
  EmptyClient,
} from "@interfaces";
import { createContext, useContext, useReducer } from "react";
import { useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";

type Action =
  | { type: "SET_CLIENT"; payload: Client | EmptyClient }
  | { type: "SET_STATUS"; payload: ClientStatus }
  | { type: "SET_ORIGINAL_CLIENT"; payload: Client | EmptyClient }
  | { type: "SET_FILTERS"; payload: ClientFilters };

const reducer = (state: ClientState, action: Action): ClientState => {
  switch (action.type) {
    case "SET_CLIENT":
      return { ...state, client: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_ORIGINAL_CLIENT":
      return {
        ...state,
        originalClient: action.payload,
        client: action.payload,
      };

    default:
      return state;
  }
};

export const ClientStoreContext = createContext<{
  clientState: ClientState;
  clientDispatch: React.Dispatch<Action>;
}>({
  clientState: {
    client: null,
    originalClient: null,
    status: "view",
    form: null,
  },
  clientDispatch: () => {},
});

export const useClientStore = () => {
  const context = useContext(ClientStoreContext);
  if (context === undefined) {
    throw new Error("useClientStore must be used within a ClientStoreProvider");
  }
  return context;
};

export const ClientStoreProvider: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, {
    originalClient: defaultClient,
    client: defaultClient,
    status: "pending",
    form: useForm(),
  });

  return (
    <ClientStoreContext.Provider
      value={{
        clientState: state,
        clientDispatch: dispatch,
      }}
    >
      <Outlet />
    </ClientStoreContext.Provider>
  );
};
