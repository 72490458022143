import { EmptyClient } from "@interfaces";

export const defaultClient: EmptyClient = {
  name: "",
  email: "",
  phone: "",
  country: "",
  address: "",
  city: "",
  contact_info: "",
  description: "",
  jobs: [],
};
