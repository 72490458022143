import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

const SignUp = () => {
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { username, password, confirmPassword } = e.target.elements;
    if (password.value !== confirmPassword.value) {
      setErrorMessage(["Passwords don't match"]);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/signup`, {
        username: username.value,
        password: password.value,
      })
      .then((response) => {
        setErrorMessage([]);
        navigate("/signin");
      })
      .catch((error) => {
        setErrorMessage(
          Array.isArray(error.response.data.message)
            ? error.response.data.message
            : [error.response.data.message]
        );
      });
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder="Enter your username"
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Confirm Password</Label>
            <Input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm your password"
            />
          </FormGroup>
          <Button type="submit" color="primary">
            Sign Up
          </Button>
        </Form>
      </div>

      <div
        style={{
          color: "red",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <ul style={{ marginTop: "30px" }}>
          {errorMessage.map((message: string) => (
            <li>{message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { SignUp };
