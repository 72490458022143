import { ReactComponent as Logo } from "@assets/loading.svg";
import { Project } from "@interfaces";
import { Chip } from "@mui/material";
import {
  calcDuration,
  calcTotalDuration,
  formatProjectResponsibilities,
  groupProjectsByCompany,
  wrapLinksInAnchorTags,
} from "@utils";

const TalentPublicProjects = (props: any) => {
  const talent = props.talent;

  return (
    <>
      {" "}
      {talent?.cv_projects?.length ? (
        <>
          {" "}
          <div className="header">
            <Logo width={"1.4em"} className="me-2" />
            <h4 className="mb-0">Experience</h4>
            <h6 className="text-muted ms-4 mb-0">
              {calcTotalDuration(talent?.cv_projects)}
            </h6>
          </div>
          <div className="d-flex flex-column gap-0">
            {groupProjectsByCompany(talent?.cv_projects).map(
              (projects: Project[], index: number) => {
                return (
                  <div className="timeline experience" key={index}>
                    {projects.length > 1 ? (
                      <>
                        <h5 className=" mt-3 ms-2">
                          <div className="font-weight-bold">
                            {projects[0].company_name}
                          </div>
                        </h5>
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      className={
                        projects.length > 1
                          ? "timeline-items"
                          : "timeline-items-single"
                      }
                    >
                      {projects.map((project: Project, index: number) => {
                        return (
                          <div className="timeline-item" key={index}>
                            <h3 className={projects.length > 1 ? "" : "single"}>
                              {project.project_role ? (
                                <span className="bend-primary-inverted bullet-div">
                                  {project.project_role}
                                </span>
                              ) : null}
                              {projects.length > 1 ? (
                                ""
                              ) : (
                                <>
                                  {project.company_name ? (
                                    <span className="  font-weight-bold bullet-div">
                                      {project.company_name}
                                    </span>
                                  ) : null}
                                </>
                              )}
                              {project.project_name ? (
                                <span className="  font-weight-bold text-muted bullet-div">
                                  {project.project_name}
                                </span>
                              ) : (
                                ""
                              )}
                              <span className=" text-muted mb-0 bullet-div">
                                {calcDuration(
                                  projects[index].from,
                                  projects[index].to,
                                  projects[index].ongoing
                                )}
                              </span>
                            </h3>
                            {project?.technologies?.every((el: any) => !!el) ? (
                              <div className="technologies">
                                {project?.technologies?.map(
                                  (data: any, index: number) => {
                                    return (
                                      <Chip
                                        className="other-tech"
                                        key={index}
                                        label={data}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {project.project_description.length ? (
                              <div
                                className="experience-description"
                                dangerouslySetInnerHTML={{
                                  __html: wrapLinksInAnchorTags(
                                    project.project_description
                                  ),
                                }}
                              ></div>
                            ) : (
                              ""
                            )}
                            {project.project_responsibilities.length ? (
                              <div className="experience-responsibilities">
                                <h5 className="text-muted">Responsibilities</h5>
                                {formatProjectResponsibilities(
                                  project.project_responsibilities
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export { TalentPublicProjects };
