import { TalentJobsForm, TalentJobStatusTextField } from "@components/talents";
import { Job, TalentJob } from "@interfaces";
import { Button } from "@mui/material";
import { useTalentStore } from "@store";
import { smartDate } from "@utils";
import axios from "axios";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
} from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TalentJobsList = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const [formOpen, setFormOpen] = React.useState(false);
  const [jobs, setJobs] = React.useState<Job[]>([]);

  const fetchJobs = async () => {
    axios.get(process.env.REACT_APP_API_URL + "/jobs").then((res) => {
      setJobs(res.data);
    });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const deleteJob = (job: TalentJob) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/jobs/talents/`, {
        data: {
          job_id: job.job_id,
          talent_id: state.talent?.id,
          status: job.status,
        },
      })
      .then((res) => {
        props.fetchTalent();
      });
  };

  const submitStatusChanges = (
    talentJob: Omit<TalentJob, "id" | "created_at" | "talent" | "job">
  ) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/jobs/talents/`, {
        job_id: talentJob.job_id,
        talent_id: state.talent?.id,
        status: talentJob.status,
      })
      .then((res) => {
        props.fetchTalent();
      });
  };

  return (
    <>
      <div>
        <MDBCard className="border-0">
          <MDBCardHeader>
            <div className="d-flex justify-content-between  ">
              <MDBCardText>Jobs</MDBCardText>
              <Button
                className="p-0"
                variant="text"
                onClick={() => setFormOpen(!formOpen)}
              >
                +add
              </Button>
            </div>
          </MDBCardHeader>

          <MDBCardBody className="talent-jobs-list ">
            {formOpen ? (
              <>
                <TalentJobsForm
                  jobs={jobs}
                  fetchTalent={props.fetchTalent}
                  setFormOpen={setFormOpen}
                  logTalentActivity={props.logTalentActivity}
                />
              </>
            ) : (
              ""
            )}
            {state.talent?.jobs?.map((job: TalentJob, index: number) => (
              <div className="talent-jobs-item " key={index}>
                <div className="d-flex justify-content-between flex-row">
                  <Link
                    className="font-weight-bold"
                    style={{ fontSize: 18 }}
                    to={`/jobs/${job.job.id}`}
                  >
                    <div className="d-flex flex-row align-items-center gap-1">
                      {job.job.name}
                    </div>
                  </Link>
                  <Button
                    className="p-0"
                    style={{ color: "red" }}
                    variant="text"
                    onClick={() => deleteJob(job)}
                  >
                    delete
                  </Button>
                </div>
                <div>
                  {job.job.client?.name}
                  {" | "}
                  {job.job.client?.country}
                </div>
                <TalentJobStatusTextField
                  talentJob={job}
                  submitStatusChanges={submitStatusChanges}
                  logTalentActivity={props.logTalentActivity}
                />
                <div
                  className="d-flex justify-content-end"
                  style={{ fontSize: 12 }}
                >
                  {smartDate(job.created_at)}
                </div>
              </div>
            ))}
          </MDBCardBody>
        </MDBCard>
      </div>
    </>
  );
};

export { TalentJobsList };
