import { NotFound } from "@pages/error";
import { Main } from "@pages/main";
import { PdfPage } from "@pages/pdf";
import { AuthStoreProvider } from "@store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PublicRouter } from "./PublicRouter";
const AppRouter = (props: any) => {
  return (
    <AuthStoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/public/*" element={<PublicRouter />} />
          <Route path="/pdf" element={<PdfPage />} />
          <Route path="/*" element={<Main />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthStoreProvider>
  );
};

export { AppRouter };
