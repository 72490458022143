import { TalentTechnologyForm } from "@components/talents";
import { SelectedTechnology } from "@interfaces";
import { Button } from "@mui/material";
import { useTalentStore } from "@store";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
} from "mdb-react-ui-kit";
import { useRef } from "react";

const TalentCoreTechnology = (props: any) => {
  const cardRef = useRef<any>();
  const { state, dispatch } = useTalentStore();

  if (!state.talent?.cv_core_technologies?.length && state.status !== "edit")
    return null;

  return (
    <>
      <MDBCard ref={cardRef}>
        <MDBCardHeader>
          <div className="d-flex flex-row align-items-center gap-2">
            Core Technologies/Skills
            <div
              style={{
                display: "inline-block",
                color: "orange",
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: 300,
              }}
            >
              public
            </div>
          </div>
        </MDBCardHeader>
        <MDBCardBody className="pt-0 pb-2">
          <div className="talent-skill-list">
            {state.talent?.cv_core_technologies?.map(
              (tech: SelectedTechnology, index: number) => {
                return (
                  <div key={index}>
                    <TalentTechnologyForm
                      index={index}
                      technology={tech.technology}
                      experience={tech.experience_in_years}
                      form={"core"}
                      handleTechnologyDelete={props.handleTechnologyDelete}
                    />
                  </div>
                );
              }
            )}
          </div>

          {state.status === "edit" ? (
            <div>
              <Button
                onClick={(e) => {
                  const changes = {
                    cv_core_technologies: [
                      ...state.talent?.cv_core_technologies,
                      {
                        technology: "",
                        experience_in_years: "",
                      },
                    ],
                  };
                  dispatch({
                    type: "SET_TALENT",
                    payload: { ...state.talent, ...changes },
                  });
                }}
                className="my-2"
                size="small"
                variant="outlined"
                style={{
                  outline: "none",
                  boxShadow: "none",
                  borderRadius: 30,
                }}
              >
                + Add technology
              </Button>
            </div>
          ) : (
            ""
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
export default TalentCoreTechnology;
