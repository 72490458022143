import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTalentStore } from "@store";
import dayjs, { Dayjs } from "dayjs";
import { MDBCardText } from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";

const TalentDatePicker = (props: any) => {
  const addMode = props.addMode;
  const date = props.dateFrom ? props.dateFrom : props.dateTo;
  const index = props.index;
  const editIndex = props.editIndex;
  const [open, setOpen] = useState<boolean>(false);
  const calendarRef = useRef<any>(null);
  const { state, dispatch } = useTalentStore();
  useEffect(() => {
    document.addEventListener("mousedown", (e) => setOpen(false));
    return () => {
      document.removeEventListener("mousedown", (e) => setOpen(false));
    };
  }, []);

  if (props.ongoing) {
    return (
      <div
        className={
          "d-flex flex-row align-items-center" +
          (editIndex === index || addMode ? " calendar" : "")
        }
      >
        Present
      </div>
    );
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          ref={calendarRef}
          open={open}
          views={["year", "month"]}
          openTo="year"
          label="From"
          value={date}
          OpenPickerButtonProps={{
            style: {
              outline: "none",
              boxShadow: "none",
              padding: "0px",
              margin: "0px",
            },
          }}
          PopperProps={{
            onMouseDown: (e) => {
              e.stopPropagation();
            },
          }}
          onChange={(newValue: Dayjs | null) => {
            props.onChange(newValue?.format("YYYY-MM")!);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div
              className={
                "d-flex flex-row align-items-center" +
                (editIndex === index || addMode ? " calendar" : "") +
                (state.status === "edit" ? " talent-hoverable" : "")
              }
              onClick={() => {
                if (state.status === "edit") setOpen(!open);
              }}
            >
              <MDBCardText className=" p-0 m-0  text-nowrap" ref={inputRef}>
                {dayjs(date).isSame(new Date("0000"))
                  ? "None"
                  : dayjs(date).format("MMM YYYY")}
              </MDBCardText>
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  );
};
export default TalentDatePicker;
