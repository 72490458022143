import { Autocomplete, TextField } from "@mui/material";
import { useJobStore } from "@store";
import { useEffect, useRef } from "react";

const JobStatus = (props: any) => {
  const { jobState, jobDispatch } = useJobStore();
  const submitChanges = props.submitChanges;
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    } else {
      submitChanges();
    }
  }, [jobState.job?.status]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      blurOnSelect
      autoSelect
      disabled={jobState.status === "edit"}
      options={jobState.context?.job_statuses! || []}
      onChange={(e: any, value: any) => {
        jobDispatch({
          type: "SET_JOB",
          payload: { ...jobState.job, status: value },
        });
      }}
      value={jobState.job?.status || null}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          disabled={jobState.status === "edit"}
        />
      )}
    />
  );
};
export { JobStatus };
