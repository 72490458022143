import {
  TalentCalendarRange,
  TalentExperienceTechnologies,
  TalentNestedTextField,
  TalentProgressToggle,
} from "@components/talents";
import { Project } from "@interfaces";
import { Button, IconButton } from "@mui/material";
import { useTalentStore } from "@store";
import { sortByDate } from "@utils";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { GoTrash } from "react-icons/go";

const TalentExperience = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const addMode = state.talent?.id ? false : true;

  const deleteProject = (index: number) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      const changes = {
        cv_projects: state.talent?.cv_projects.filter(
          (proj: any, i: number) => i !== index
        )!,
      };
      dispatch({
        type: "SET_TALENT",
        payload: { ...state.talent, ...changes },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "SET_TALENT",
      payload: {
        ...state.talent,
        cv_projects: sortByDate<Project>(state.talent?.cv_projects),
      },
    });
  }, []);

  if (state.talent?.cv_projects?.length === 0 && state.status !== "edit")
    return null;

  return (
    <MDBCard className="talent-experience-card">
      <MDBCardHeader>
        <MDBRow>
          <MDBCol className="d-flex flex-grow-1 justify-content-start">
            <div className="d-flex flex-row align-items-center gap-2">
              Experience
              <div
                style={{
                  display: "inline-block",
                  color: "orange",
                  fontSize: 12,
                  fontStyle: "italic",
                  fontWeight: 300,
                }}
              >
                public
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>

      <MDBCardBody className="p-0 px-3">
        {state.talent?.cv_projects?.length
          ? state.talent?.cv_projects?.map(
              (project: Project, index: number) => {
                return (
                  <MDBCard
                    key={index}
                    className={
                      "project-card border-0 " + (index ? "border-top" : " ")
                    }
                  >
                    <MDBCardBody className="project-card-body p-2 card-body-element">
                      <div
                        className={
                          "delete" + (state.status === "edit" ? "" : " d-none")
                        }
                      >
                        <IconButton onClick={(e) => deleteProject(index)}>
                          <GoTrash color="#dc3546" />
                        </IconButton>
                      </div>
                      <div className="talent-experience-body-header">
                        <TalentNestedTextField
                          index={index}
                          field="company_name"
                          nestedField="cv_projects"
                          label="Company Name"
                        />
                        <TalentNestedTextField
                          index={index}
                          field="project_role"
                          nestedField="cv_projects"
                          label="Role"
                        />
                        <TalentNestedTextField
                          index={index}
                          field="project_name"
                          nestedField="cv_projects"
                          label="Project Name"
                        />
                        <TalentCalendarRange
                          addMode={addMode}
                          index={index}
                          dateObjects={state.talent?.cv_projects}
                          objectType="cv_projects"
                        />

                        <TalentProgressToggle
                          index={index}
                          nested_field="cv_projects"
                        />
                      </div>

                      <div className="project-body">
                        <TalentNestedTextField
                          minRows={4}
                          index={index}
                          field="project_description"
                          nestedField="cv_projects"
                          label="Project Description"
                          link
                        />
                        <TalentNestedTextField
                          minRows={4}
                          index={index}
                          field="project_responsibilities"
                          nestedField="cv_projects"
                          label="Project Responsibilities"
                          list
                        />
                        <TalentExperienceTechnologies index={index} />
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                );
              }
            )
          : ""}
        {state.status === "edit" ? (
          <div>
            <Button
              className="my-2"
              size="small"
              variant="outlined"
              style={{
                outline: "none",
                boxShadow: "none",
                borderRadius: 30,
              }}
              onClick={() => {
                dispatch({
                  type: "SET_TALENT",
                  payload: {
                    ...state.talent,
                    cv_projects: [
                      ...state.talent?.cv_projects!,
                      {
                        company_name: "",
                        project_name: "",
                        project_role: "",
                        project_description: "",
                        project_responsibilities: "",
                        project_technologies: [],
                        from: new Date("0000"),
                        to: new Date("0000"),
                        ongoing: false,
                      },
                    ],
                  },
                });
              }}
            >
              + Add Project
            </Button>
          </div>
        ) : (
          ""
        )}
      </MDBCardBody>
    </MDBCard>
  );
};
export default TalentExperience;
