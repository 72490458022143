import { TalentTechnologyForm } from "@components/talents";
import { SelectedTechnology } from "@interfaces";
import { Button } from "@mui/material";
import { useTalentStore } from "@store";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";

const TalentOtherTechnology = (props: any) => {
  const { state, dispatch } = useTalentStore();

  if (!state.talent?.cv_other_technologies?.length && state.status !== "edit")
    return null;

  return (
    <MDBCard>
      <MDBCardHeader>
        <div className="d-flex flex-row align-items-center gap-2">
          Other Technologies/Skills
          <p
            style={{
              display: "inline-block",
              color: "orange",
              fontSize: 12,
              fontStyle: "italic",
              fontWeight: 300,
            }}
          >
            public
          </p>
        </div>
      </MDBCardHeader>
      <MDBCardBody className="pt-0 pb-2">
        <div className="talent-skill-list">
          {state.talent?.cv_other_technologies?.map(
            (tech: SelectedTechnology, index: number) => {
              return (
                <div key={index}>
                  <TalentTechnologyForm
                    index={index}
                    technology={tech.technology}
                    experience={tech.experience_in_years}
                    form={"other"}
                    handleTechnologyDelete={props.handleTechnologyDelete}
                  />
                </div>
              );
            }
          )}
        </div>

        {state.status === "edit" ? (
          <div>
            <Button
              className="my-2"
              size="small"
              variant="outlined"
              style={{
                outline: "none",
                boxShadow: "none",
                borderRadius: 30,
              }}
              onClick={(e) => {
                const changes = {
                  cv_other_technologies: [
                    ...state.talent?.cv_other_technologies,
                    {
                      technology: "",
                      experience_in_years: "",
                    },
                  ],
                };
                dispatch({
                  type: "SET_TALENT",
                  payload: { ...state.talent, ...changes },
                });
              }}
            >
              + Add technology
            </Button>
          </div>
        ) : (
          ""
        )}
      </MDBCardBody>
    </MDBCard>
  );
};
export default TalentOtherTechnology;
