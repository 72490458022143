import { TalentCV, TalentTextField } from "@components/talents";
import { useTalentStore, useTalentsStore } from "@store";
import { MDBCard } from "mdb-react-ui-kit";
import { forwardRef } from "react";

const TalentGeneralInfo = forwardRef((props: any, ref: any) => {
  const { state, dispatch } = useTalentStore();
  const { types, languageLevels, roles, seniorities, status, technologies } =
    useTalentsStore();

  return (
    <MDBCard className="general-info ">
      <div className="  general-info-grid">
        <TalentTextField field="name" label="Name" required />
        <TalentTextField
          required
          field="type"
          label="Type"
          select
          options={types}
        />
        <TalentTextField
          field="seniority"
          label="Seniority"
          select
          options={seniorities}
        />
        <TalentTextField field="linked_in_url" label="Profile" link />
        <TalentTextField field="role" label="Role" select options={roles} />
        <TalentTextField field="email" label="Email address" />
        <TalentTextField field="cv_experience" label="Experience (years)" />
        <TalentTextField field="phone" label="Phone number" />
      </div>

      <TalentCV submitChanges={props.submitChanges} addMode={props.addMode} />
    </MDBCard>
  );
});
export default TalentGeneralInfo;
