import { useTalentsStore, useTalentStore } from "@store";
import axios from "axios";
import TalentCoreTechnologies from "./TalentCoreTechnologies";
import TalentOtherTechnologies from "./TalentOtherTechnologies";

const TalentTechnologiesWrapper = (props: any) => {
  const { technologies } = useTalentsStore();
  const { state, dispatch } = useTalentStore();

  const handleCreateTechnology = async (technology: string): Promise<void> => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/talents/technologies`, {
          technology,
        })
        .then((res) => {
          technologies?.push(res.data);
        });
    } catch (err) {
      window.alert("Error creating technology");
    }
  };

  const handleTechnologyDelete = (
    e: any,
    index: number,
    technologyType: "core" | "other"
  ) => {
    const technologyArrayKey =
      technologyType === "core"
        ? "cv_core_technologies"
        : "cv_other_technologies";
    const changes = {
      [technologyArrayKey]: state.talent?.[technologyArrayKey]?.filter(
        (tech: any, i: number) => i !== index
      ),
    };
    dispatch({ type: "SET_TALENT", payload: { ...state.talent, ...changes } });
  };

  return (
    <>
      <TalentCoreTechnologies handleTechnologyDelete={handleTechnologyDelete} />
      <TalentOtherTechnologies
        handleTechnologyDelete={handleTechnologyDelete}
      />
    </>
  );
};

export default TalentTechnologiesWrapper;
