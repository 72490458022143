import { BEND_PRIMARY } from "@const";
import { Button } from "@mui/material";
import "@styles/public.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const JobPublicCTA = () => {
  return (
    <>
      <MDBCard className=" cta-item border-0 d-flex flex-column justify-content-center book-cta">
        <MDBCardBody>
          <h5 className="d-flex justify-content-center font-weight-bold">
            Ready to take the next step?
          </h5>
          <div className="pt-2 px-3 ">
            We help professionals like you in discovering the perfect
            opportunities that align with your skills and aspirations.
          </div>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="cta-item border-0 d-flex flex-column justify-content-center md-6 p-2  py-0 expect-cta">
        <MDBCardBody className="d-flex  flex-column align-items-center">
          <h6
            className="d-flex justify-content-start font-weight-bold pb-2"
            style={{ color: BEND_PRIMARY }}
          >
            WHAT TO EXPECT
          </h6>
          <div className=" d-flex flex-column align-items-center">
            <div className="font-weight-bold mb-2">
              A call with our HR Director
            </div>
            <ul className="p-0 job-bullet">
              <li>
                <div>
                  <IoCheckmarkCircle />
                </div>
                <div>Discuss your career goals</div>
              </li>
              <li>
                <div>
                  <IoCheckmarkCircle />
                </div>
                <div>Learn about our recruitment process</div>
              </li>
              <li>
                <div>
                  <IoCheckmarkCircle />
                </div>
                <div>
                  Get answers to any question you have about our services,
                  pricing and timeline for recruitment
                </div>
              </li>
              <li>
                <div>
                  <IoCheckmarkCircle />
                </div>
                <div>Walk away with clear understanding of the next steps</div>
              </li>
            </ul>
            <Button
              size="medium"
              className="book-button bend-primary"
              variant="contained"
            >
              <Link
                target="_blank"
                to={"//calendar.app.google/mt4DTzA91CBEQQ3W9"}
              >
                <div className="">Book a call</div>
              </Link>
            </Button>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
export { JobPublicCTA };
