import type { Talent, TalentActivity } from "@interfaces";
import Button from "@mui/material/Button/Button";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, usePrompt } from "react-router-dom";

import {
  TalentAccessList,
  TalentEducationWrapper,
  TalentExperienceWrapper,
  TalentGeneralInfo,
  TalentGeneralInfoPublic,
  TalentHeaderInfo,
  TalentHistory,
  TalentJobsList,
  TalentRTE,
  TalentSkills,
} from "@components/talents";
import { defaultTalent } from "@const";
import { useTalentStore } from "@store";
import "@styles/talent.css";
import { createBrowserHistory } from "history";
import { MDBCard } from "mdb-react-ui-kit";

const TalentProfile = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const { id } = useParams();
  const addMode = id ? false : true;

  const [accessList, setAccessList] = useState<any[]>([]);
  const [talentActivity, setTalentActivity] = useState<any[]>([]);
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = state.form!;
  const formRef = useRef<any>(null);

  const fetchTalent = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/talents/${id}`)
      .then((res) => {
        dispatch({ type: "SET_ORIGINAL_TALENT", payload: res.data });
      });
  };

  const fetchTalentActivity = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/talents/activity/${id}`)
      .then((res) => {
        setTalentActivity(res.data);
      });
  };

  const fetchAccessList = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/talents/access/${id}`)
      .then((res) => {
        setAccessList(res.data);
      });
  };

  const logActivity = async (
    type: TalentActivity["type"],
    activity: TalentActivity["activity"]
  ) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/talents/activity`, {
        activity,
        type,
        talent_id: +id!,
      })
      .then(() => {
        fetchTalentActivity();
        fetchTalent();
      });
  };

  useEffect(() => {
    if (!addMode) {
      dispatch({ type: "SET_STATUS", payload: "pending" });
      Promise.all([
        fetchTalent(),
        fetchTalentActivity(),
        fetchAccessList(),
      ]).then(() => {
        dispatch({ type: "SET_STATUS", payload: "view" });
      });
    } else {
      dispatch({ type: "SET_STATUS", payload: "edit" });
    }
    return () => {
      dispatch({ type: "SET_ORIGINAL_TALENT", payload: defaultTalent });
      dispatch({ type: "SET_STATUS", payload: "pending" });
    };
  }, []);

  const createTalent = (talent: Talent) => {
    dispatch({ type: "SET_STATUS", payload: "view" });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/talents`,
        serialize(
          { ...state.talent, status_id: 1 },
          {
            indices: true,
            allowEmptyArrays: true,
            nullsAsUndefineds: true,
          }
        )
      )
      .then(async (res) => {
        dispatch({ type: "SET_ORIGINAL_TALENT", payload: res.data });
        navigate(`/talents/${res.data.id}`, { replace: true });
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const deleteTalent = (e: any) => {
    window.confirm("Are you sure you want to delete this talent?") &&
      axios
        .delete(`${process.env.REACT_APP_API_URL}/talents/${id}`)
        .then((res) => {
          navigate("/talents", { replace: true });
        })
        .catch((err) => {
          window.alert(err);
        });
  };

  const submitChanges = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/talents/${id}`,
        serialize(state.talent, {
          indices: true,
          allowEmptyArrays: true,
          nullsAsUndefineds: true,
        })
      )
      .then(async (res) => {
        dispatch({ type: "SET_ORIGINAL_TALENT", payload: res.data });
        fetchTalentActivity();
      })
      .catch((err) => {
        window.alert(err);
      })
      .finally(() => {
        dispatch({ type: "SET_STATUS", payload: "view" });
      });
  };

  usePrompt(
    "Are you sure you want to leave? Your changes will be lost.",

    !isSubmitting && state.status === "edit"
  );

  const navigateBack = () => {
    navigate("/talents" + history.location.search);
  };

  const submitForm = async () => {
    if (addMode) {
      await createTalent(state.talent as Talent);
    } else {
      await submitChanges();
    }
  };

  useEffect(() => {
    if (state.status === "view") reset();
    return () => {
      reset();
    };
  }, [state.status]);

  return (
    <>
      {state.status !== "pending" ? (
        <div className="talent-container">
          <TalentHeaderInfo
            addMode={addMode}
            navigateBack={navigateBack}
            submitChanges={submitChanges}
            formRef={formRef}
            logActivity={logActivity}
          />
          <div className="talent-main-columns">
            <div className="talent-bigger-column">
              {state.status !== "edit" ? (
                <TalentJobsList
                  fetchTalent={fetchTalent}
                  logTalentActivity={logActivity}
                />
              ) : (
                ""
              )}

              <form
                className="d-flex gap-4 flex-column"
                ref={formRef}
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <TalentGeneralInfo
                  submitChanges={submitChanges}
                  addMode={addMode}
                />
                {state.talent?.internal_notes || state.status === "edit" ? (
                  <MDBCard className="border-0 talent-internal-notes">
                    <TalentRTE label="Internal notes" field="internal_notes" />
                  </MDBCard>
                ) : (
                  ""
                )}
                <TalentGeneralInfoPublic
                  submitChanges={submitChanges}
                  addMode={addMode}
                />
                <TalentExperienceWrapper />
                <TalentSkills />
                <TalentEducationWrapper />
              </form>
              {!addMode ? (
                <TalentAccessList
                  id={id}
                  accessList={{ accessList, setAccessList }}
                />
              ) : (
                ""
              )}
            </div>
            <div className="talent-smaller-column">
              <TalentHistory
                logActivity={logActivity}
                talentActivity={talentActivity}
              />
            </div>
          </div>
          {addMode ? (
            <Button
              className={"talent-action-button"}
              variant="contained"
              size="small"
              onClick={(e) => formRef.current?.requestSubmit()}
            >
              {"Add talent"}
            </Button>
          ) : (
            <Button
              className="talent-action-button delete"
              variant={"outlined"}
              size="small"
              onClick={(e) => deleteTalent(e)}
            >
              {"Delete talent"}
            </Button>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export { TalentProfile };
