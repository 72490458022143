import { Talent } from "@interfaces";
import { IconButton } from "@mui/material";
import { useTalentStore } from "@store";
import { AiOutlineFieldTime } from "react-icons/ai";

type TalentProgressToggleProps = {
  index: number;
  nested_field: string;
};

const TalentProgressToggle = ({
  index,
  nested_field,
}: TalentProgressToggleProps) => {
  const { state, dispatch } = useTalentStore();

  return (
    <div
      className={
        (state.status === "edit" ? "" : "invisible") + " talent-in-progress"
      }
    >
      <IconButton
        className={
          (state.talent?.[nested_field][index]?.ongoing
            ? "toggle-on "
            : "toggle-off ") + "talent-in-progress-btn"
        }
        onClick={() =>
          dispatch({
            type: "SET_TALENT",
            payload: {
              ...state.talent,
              [nested_field]: state.talent?.[nested_field]?.map(
                (project: any, i: number) => {
                  if (i === index) {
                    return {
                      ...project,
                      ongoing: !project.ongoing,
                    };
                  }
                  return project;
                }
              ),
            } as Talent,
          })
        }
        style={{
          outline: "none",
          boxShadow: "none",
        }}
      >
        <AiOutlineFieldTime />
      </IconButton>
    </div>
  );
};
export default TalentProgressToggle;
