import { JobGeneralInfo, JobHeader, JobTalentsList } from "@components/jobs";
import { defaultJob } from "@const";
import type { Job } from "@interfaces";
import Button from "@mui/material/Button/Button";
import { useJobStore } from "@store";
import "@styles/jobs.css";
import axios from "axios";
import { createBrowserHistory } from "history";
import { useEffect, useRef } from "react";
import { BsTrash } from "react-icons/bs";
import {
  useNavigate,
  useParams,
  usePrompt,
  useSearchParams,
} from "react-router-dom";

const JobProfile = (props: any) => {
  const { jobState: state, jobDispatch: dispatch } = useJobStore();
  const { id } = useParams();
  const addMode = props.addMode;
  const [searchParams] = useSearchParams();
  const clientId = parseInt(searchParams.get("clientId")!);
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = state.form!;
  const formRef = useRef<any>(null);

  const fetchJob = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/jobs/${id}`)
      .then((res) => {
        dispatch({ type: "SET_ORIGINAL_JOB", payload: res.data });
      });
  };

  useEffect(() => {
    if (!addMode) {
      dispatch({ type: "SET_STATUS", payload: "pending" });
      Promise.all([fetchJob()]).then(() => {
        dispatch({ type: "SET_STATUS", payload: "view" });
      });
    } else {
      dispatch({ type: "SET_STATUS", payload: "edit" });
    }
    return () => {
      dispatch({ type: "SET_ORIGINAL_JOB", payload: defaultJob });
      dispatch({ type: "SET_STATUS", payload: "pending" });
    };
  }, [id]);

  const createJob = (job: Job) => {
    dispatch({ type: "SET_STATUS", payload: "pending" });

    axios
      .post(`${process.env.REACT_APP_API_URL}/jobs`, state.job)
      .then(async (res) => {
        dispatch({ type: "SET_STATUS", payload: "view" });
        dispatch({ type: "SET_ORIGINAL_JOB", payload: res.data });
        navigate(`/jobs/${res.data.id}`, { replace: true });
      })
      .catch((err) => {
        dispatch({ type: "SET_STATUS", payload: "error" });

        window.alert(err);
      });
  };

  const deleteJob = (e: any) => {
    window.confirm("Are you sure you want to delete this job?") &&
      axios
        .delete(`${process.env.REACT_APP_API_URL}/jobs/${id}`)
        .then((res) => {
          navigate("/jobs", { replace: true });
        })
        .catch((err) => {
          window.alert(err);
        });
  };

  const submitChanges = async () => {
    await axios
      .put(`${process.env.REACT_APP_API_URL}/jobs/${id}`, {
        ...state.job,
        talents: [],
      })
      .then(async (res) => {
        dispatch({ type: "SET_ORIGINAL_JOB", payload: res.data });
      })
      .catch((err) => {
        window.alert(err);
      })
      .finally(() => {
        dispatch({ type: "SET_STATUS", payload: "view" });
      });
  };

  usePrompt(
    "Are you sure you want to leave? Your changes will be lost.",

    !isSubmitting && state.status === "edit"
  );

  const navigateBack = () => {
    navigate(-1);
  };

  const submitForm = async () => {
    if (addMode) {
      await createJob(state.job as Job);
    } else {
      await submitChanges();
    }
  };

  useEffect(() => {
    if (state.status === "view") reset();
    return () => {
      reset();
    };
  }, [state.status]);

  return (
    <>
      {state.status !== "pending" ? (
        <div className="job-container">
          <JobHeader
            addMode={addMode}
            formRef={formRef}
            navigateBack={navigateBack}
            clientId={clientId}
            submitChanges={submitChanges}
          />
          <div className="talent-main-columns">
            <div className="job-bigger-column">
              <form
                className="d-flex gap-4 flex-column"
                ref={formRef}
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <JobGeneralInfo
                  submitChanges={submitChanges}
                  clientId={clientId}
                />
              </form>
            </div>
            {!addMode ? (
              <div className="job-smaller-column">
                <JobTalentsList fetchJob={fetchJob} />
              </div>
            ) : (
              ""
            )}
          </div>

          {addMode ? (
            <Button
              className={"job-action-button"}
              variant="contained"
              size="small"
              onClick={(e) => formRef.current?.requestSubmit()}
            >
              {"Add job"}
            </Button>
          ) : (
            <>
              <Button
                className="job-action-button delete gap-4 px-2 d-flex justify-content-start"
                variant={"text"}
                size="small"
                onClick={(e) => deleteJob(e)}
              >
                <BsTrash />
                {"Delete job"}
              </Button>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export { JobProfile };
