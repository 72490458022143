import { ReactComponent as Logo } from "@assets/loading.svg";
import { Education } from "@interfaces";
import { sortByDate } from "@utils";
import dayjs from "dayjs";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

const TalentPublicEducation = (props: any) => {
  const talent = props.talent;

  return (
    <>
      {talent?.cv_education?.length ? (
        <MDBRow className="flex-row flex-wrap public-education">
          <div className="d-flex align-items-center">
            <Logo width={"1.4em"} className="me-2" />
            <h4 className="d-inline my-4">Education</h4>
          </div>
          {sortByDate<Education>(talent?.cv_education).map(
            (edu: any, index: number) => {
              return (
                <MDBCol
                  style={{ flexBasis: "50%" }}
                  key={index}
                  className="education-item d-flex justify-content-start flex-column  text-wrap"
                >
                  <h5 className="mb-0">
                    <div className="font-weight-bold bullet-div">
                      {edu.name}
                    </div>
                  </h5>
                  <div className="text-muted">
                    <div className="bullet-div">{edu.institution}</div>
                    {!dayjs(edu.from).isSame(new Date("0000")) &&
                    !dayjs(edu.to).isSame(new Date("0000")) ? (
                      <div>
                        {dayjs(edu.from).format("MMM `YY") + " - "}
                        {edu.ongoing
                          ? " In progress"
                          : dayjs(edu.to).isSame(new Date("0000"))
                          ? ""
                          : "" + dayjs(edu.to).format("MMM `YY")}
                      </div>
                    ) : edu.ongoing ? (
                      " In progress"
                    ) : (
                      ""
                    )}
                  </div>
                </MDBCol>
              );
            }
          )}
        </MDBRow>
      ) : (
        ""
      )}
    </>
  );
};
export { TalentPublicEducation };
