import axios, { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { PDFDocumentData, PdfDocProps } from "../interfaces/Pdf.interface";

const generatePdfDocument = (id: number) => {
  return axios.get<
    PDFDocumentData<PdfDocProps>,
    AxiosResponse<{ data: number[] }>
  >(`${process.env.REACT_APP_API_URL}/talents/generate/${id}`);
};

export const useGeneratePDFBlobURL = () => {
  const [pdfBlobURL, setPdfBlobURL] = useState<string>();
  const [isGenerated, setIsGenerated] = useState(false);

  useEffect(() => {
    return () => {
      if (pdfBlobURL) {
        URL.revokeObjectURL(pdfBlobURL);
      }
    };
  }, []);

  const downloadPdf = useCallback((filename: string, id: number) => {
    setIsGenerated(true);
    generatePdfDocument(id)
      .then(({ data: pdfBufferData }) => {
        const blob = new Blob([new Uint8Array(pdfBufferData.data)], {
          type: "application/pdf",
        });
        const blobURL = URL.createObjectURL(blob);

        setPdfBlobURL(blobURL);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("style", "display: none");
        link.href = blobURL!;
        link.download = filename;

        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
        }, 300);
      })
      .catch((err) => {
        setIsGenerated(false);
      })
      .finally(() => {
        setIsGenerated(false);
      });
  }, []);

  return {
    downloadPdf,
    isGenerated,
  };
};
