import { FilterInput } from "@components/talents";
import { IFilterUpdatePayload, IItemsStateFilters } from "@interfaces";
import { useFilterContext } from "@store";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Filters = () => {
  const location = useLocation();
  const { state, dispatch } = useFilterContext();

  const [filters, setFilters] = useState<IItemsStateFilters | null>(
    state.filters
  );

  const onFilterChange = (filter: IFilterUpdatePayload) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      page: 0,
      [filter.key]: filter.value,
    }));
  };

  const onResetFilter = () => {
    setFilters({ page: 0, take: 10 });
  };

  useEffect(() => {
    if (filters) {
      dispatch({ type: "SET_FILTERS", payload: filters });
    }
  }, [filters]);
  return (
    <>
      <div className="filter-header">
        <h4 className="font-weight-normal">Filter</h4>
        <div className="div-button" onClick={onResetFilter}>
          Reset filter
        </div>
      </div>
      <FilterInput filters={filters} onFilterChange={onFilterChange} />
    </>
  );
};

export default Filters;
