import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = React.useState<string | null>();
  const { username } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/profile/${username}`, {
        withCredentials: true,
      })
      .then((res) => {
        setUser(res.data.username);
      })
      .catch((err) => {});
  }, [username]);

  return (
    <div>
      <h1>{user}</h1>
    </div>
  );
};

export { Profile };
