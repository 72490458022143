import { ReactComponent as Logo } from "@assets/logo.svg";
const NotFound = () => {
  return (
    <div className=" d-flex justify-content-center error-404">
      <div
        className="d-flex flex-row align-items-center  "
        style={{ fontSize: 80 }}
      >
        4<Logo className="bend-logo " />4
      </div>
    </div>
  );
};

export { NotFound };
