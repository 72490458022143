import type { Client, Job } from "@interfaces";
import Button from "@mui/material/Button/Button";
import axios from "axios";
import { useEffect, useRef } from "react";
import { Link, useNavigate, useParams, usePrompt } from "react-router-dom";

import { ClientGeneralInfo, ClientHeader } from "@components/clients";
import { defaultClient } from "@const";
import { useClientStore } from "@store";
import "@styles/clients.css";
import { groupJobsByStatus } from "@utils";
import { createBrowserHistory } from "history";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { BsBriefcase, BsTrash } from "react-icons/bs";

const ClientProfile = (props: any) => {
  const { clientState, clientDispatch } = useClientStore();
  const { id } = useParams();
  const addMode = id ? false : true;

  const navigate = useNavigate();
  const history = createBrowserHistory();
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = clientState.form!;
  const formRef = useRef<any>(null);

  const fetchClient = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/clients/${id}`)
      .then((res) => {
        clientDispatch({ type: "SET_ORIGINAL_CLIENT", payload: res.data });
      });
  };

  useEffect(() => {
    if (!addMode) {
      clientDispatch({ type: "SET_STATUS", payload: "pending" });
      Promise.all([fetchClient()]).then(() => {
        clientDispatch({ type: "SET_STATUS", payload: "view" });
      });
    } else {
      clientDispatch({ type: "SET_STATUS", payload: "edit" });
    }
    return () => {
      clientDispatch({ type: "SET_ORIGINAL_CLIENT", payload: defaultClient });
      clientDispatch({ type: "SET_STATUS", payload: "pending" });
    };
  }, []);

  const createClient = (client: Client) => {
    clientDispatch({ type: "SET_STATUS", payload: "pending" });

    axios
      .post(`${process.env.REACT_APP_API_URL}/clients`, clientState.client)
      .then(async (res) => {
        clientDispatch({ type: "SET_STATUS", payload: "view" });

        navigate(`/clients/${res.data.id}`, { replace: true });
      })
      .catch((err) => {
        clientDispatch({ type: "SET_STATUS", payload: "error" });

        window.alert(err);
      });
  };

  const deleteClient = (e: any) => {
    window.confirm("Are you sure you want to delete this client?") &&
      axios
        .delete(`${process.env.REACT_APP_API_URL}/clients/${id}`)
        .then((res) => {
          navigate("/clients", { replace: true });
        })
        .catch((err) => {
          window.alert(err);
        });
  };

  const submitChanges = async () => {
    await axios
      .put(`${process.env.REACT_APP_API_URL}/clients/${id}`, clientState.client)
      .then(async (res) => {
        clientDispatch({ type: "SET_ORIGINAL_CLIENT", payload: res.data });
      })
      .catch((err) => {
        window.alert(err);
      })
      .finally(() => {
        clientDispatch({ type: "SET_STATUS", payload: "view" });
      });
  };

  usePrompt(
    "Are you sure you want to leave? Your changes will be lost.",

    !isSubmitting && clientState.status === "edit"
  );

  const navigateBack = () => {
    navigate("/clients" + history.location.search);
  };

  const submitForm = async () => {
    if (addMode) {
      await createClient(clientState.client as Client);
    } else {
      await submitChanges();
    }
  };

  useEffect(() => {
    if (clientState.status === "view") reset();
    return () => {
      reset();
    };
  }, [clientState.status]);

  useEffect(() => {
    if (clientState.client?.jobs?.length) {
      clientDispatch({
        type: "SET_CLIENT",
        payload: {
          ...clientState.client,
          jobs: groupJobsByStatus(clientState.client?.jobs),
        },
      });
    }
  }, [clientState.client]);

  return (
    <>
      {clientState.status !== "pending" ? (
        <div className="client-container">
          <ClientHeader
            addMode={addMode}
            formRef={formRef}
            navigateBack={navigateBack}
          />
          <div className="client-bigger-column">
            <form
              className="d-flex gap-4 flex-column"
              ref={formRef}
              onSubmit={handleSubmit(submitForm)}
              noValidate
            >
              <ClientGeneralInfo submitChanges={submitChanges} />
            </form>
            <div className="client-job-container d-flex flex-column gap-3">
              {clientState.client?.jobs["Open"]?.length ? (
                <div>
                  <h3>Open jobs</h3>
                  <MDBCard className="border-0">
                    <MDBCardBody className="d-flex flex-column gap-4">
                      {clientState.client?.jobs?.Open.map((job: Job) => {
                        if (job.status === "Open") {
                          return (
                            <div className="client-job">
                              <Link to={`/jobs/${job.id}`}>
                                <h5 className="m-0">{job.name}</h5>
                              </Link>
                            </div>
                          );
                        }
                      })}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              ) : (
                ""
              )}
              {clientState.client?.jobs["Progress"]?.length ? (
                <div>
                  <h3>Jobs in progress</h3>
                  <MDBCard className="border-0">
                    <MDBCardBody className="d-flex flex-column gap-4">
                      {clientState.client?.jobs?.Progress.map((job: Job) => {
                        if (job.status === "Progress") {
                          return (
                            <div className="client-job">
                              <Link to={`/jobs/${job.id}`}>
                                <h5 className="m-0">{job.name}</h5>
                              </Link>
                            </div>
                          );
                        }
                      })}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              ) : (
                ""
              )}
              {clientState.client?.jobs["Archived"]?.length ? (
                <div>
                  <h3>Archived jobs</h3>
                  <MDBCard className="border-0">
                    <MDBCardBody className="d-flex flex-column gap-4">
                      {clientState.client?.jobs?.Archived.map((job: Job) => {
                        return (
                          <div className="client-job" key={job.id}>
                            <Link to={`/jobs/${job.id}`}>
                              <h5 className="m-0">{job.name}</h5>
                            </Link>
                          </div>
                        );
                      })}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {addMode ? (
            <Button
              className={"client-action-button"}
              variant="contained"
              size="small"
              onClick={(e) => formRef.current?.requestSubmit()}
            >
              {"Add client"}
            </Button>
          ) : (
            <>
              <div>
                <Button
                  className="client-action-button px-2 gap-4 d-flex justify-content-start"
                  variant={"text"}
                  size="small"
                  onClick={(e) => navigate(`/jobs/add?clientId=${id}`)}
                >
                  <BsBriefcase />
                  {"Add job"}
                </Button>
              </div>
              <Button
                className="client-action-button delete gap-4 px-2 d-flex justify-content-start"
                variant={"text"}
                size="small"
                onClick={(e) => deleteClient(e)}
              >
                <BsTrash />
                {"Delete client"}
              </Button>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export { ClientProfile };
