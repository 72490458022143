import { Talent, TalentActivity, TalentJob } from "@interfaces";
import {
  Autocomplete,
  Button,
  MenuItem,
  TextField,
  debounce,
} from "@mui/material";
import { useJobStore } from "@store";
import axios from "axios";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

export const JobTalentsForm = (props: any) => {
  const { jobState: state, jobDispatch: dispatch } = useJobStore();
  const [selectedTalent, setSelectedTalent] = useState<Talent | null>(null);
  const [status, setStatus] = useState<string>("");
  const [talents, setTalents] = useState<Talent[]>([]);
  const [talentQuery, setTalentQuery] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const renderSelectMenu = () => {
    const items = state.context?.talent_job_statuses?.map(
      (option: any, index: number) => {
        return (
          <MenuItem className="text-capitalize" key={index} value={option}>
            {option}
          </MenuItem>
        );
      }
    );
    return [
      <MenuItem
        className="text-capitalize"
        disabled
        hidden
        value={"null"}
      ></MenuItem>,
      ...(items || []),
    ];
  };

  const addJob = () => {
    axios
      .post(process.env.REACT_APP_API_URL + `/jobs/talents`, {
        job_id: state.job?.id,
        talent_id: selectedTalent?.id,
        status: status,
      })
      .then((res) => {
        props.fetchJob();
        props.setFormOpen(false);
        setTalentQuery("");
        setSelectedTalent(null);
        setStatus("");
        logTalentActivity(
          "talents_jobs_insert_activity",
          { job_id: state.job?.id, status: status },
          selectedTalent?.id!
        );
      });
  };

  const logTalentActivity = async (
    type: TalentActivity["type"],
    activity: TalentActivity["activity"],
    talent_id: number
  ) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/talents/activity`, {
      activity,
      type,
      talent_id,
    });
  };

  const fetchTalents = (newValue: string) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/talents?take=10&sort=name&order=asc&query=${newValue}`
      )
      .then((res) => {
        setTalents(res.data);
      });
  };

  const debouncedSave = useCallback(
    debounce((newValue) => fetchTalents(newValue), 1000),
    []
  );

  const updateValue = (newValue: string) => {
    setTalentQuery(newValue);
    debouncedSave(newValue);
  };

  const isTalentUnique = () => {
    const talentIds = state.job?.talents?.map(
      (talent: TalentJob) => talent.talent_id
    );
    return !talentIds?.includes(selectedTalent?.id) || "Talent already added!";
  };

  return (
    <div>
      <MDBCard>
        <form onSubmit={handleSubmit(addJob)}>
          <MDBCardBody className="d-flex flex-column gap-2">
            <Autocomplete
              size="small"
              value={selectedTalent}
              onChange={(event: any, newValue: any) => {
                setSelectedTalent(newValue);
              }}
              getOptionLabel={(option: Talent) => option.name}
              options={talents}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("job_talent", {
                    required: "Please select a talent",
                    validate: isTalentUnique,
                  })}
                  error={!!errors.job_talent}
                  helperText={<>{errors.job_talent?.message!}</>}
                  size="small"
                  label="Talent"
                  value={talentQuery}
                  onChange={(e) => updateValue(e.target.value)}
                />
              )}
            />
            <TextField
              {...register("job_talent_status", { required: true })}
              size="small"
              select
              error={!!errors.job_talent_status}
              helperText={errors.job_talent_status && "Please select a status"}
              fullWidth
              value={status}
              label={"Status"}
              onChange={(e: any) => setStatus(e.target.value)}
            >
              {renderSelectMenu()}
            </TextField>
            <div className="d-flex justify-content-end">
              <Button type="submit">Submit</Button>
              <Button onClick={() => props.setFormOpen(false)}>Cancel</Button>
            </div>
          </MDBCardBody>
        </form>
      </MDBCard>
    </div>
  );
};
