import { JobPublicCTA, JobPublicGeneralInfo } from "@components/jobs";
import { JobPublicType } from "@interfaces";
import "@styles/job.public.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const JobPublic = (props: any) => {
  const [errorMesage, setErrorMesage] = useState<string | null>(null);
  const { id } = useParams();
  const [token] = useSearchParams();
  const [job, setJob] = useState<JobPublicType | null>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      job
        ? setErrorMesage(null)
        : setErrorMesage("Something went wrong. Please try again later.");
    }, 10000);
    axios
      .get(`${process.env.REACT_APP_API_URL}/public/jobs/${id!}`)
      .then((res) => {
        setJob(res.data);
      })
      .catch((err) => {
        setErrorMesage(
          err?.message ||
            err?.response?.data?.message ||
            "Something went wrong. Please try again later. :("
        );
      });

    return () => {
      clearTimeout(timer);
    };
  }, [id, token, setJob]);

  return (
    <div>
      {job ? (
        <div className=" public-container">
          <div className="public-content">
            <div className="public-general">
              <JobPublicGeneralInfo
                job={job}
                openModal={{ openModal, setOpenModal }}
              />
            </div>
            <div className="public-cta">
              <JobPublicCTA />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          {errorMesage ? (
            <h2 className="text-danger">{errorMesage}</h2>
          ) : (
            <h2>Loading</h2>
          )}
        </div>
      )}
    </div>
  );
};

export { JobPublic };
