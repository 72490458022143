import { BEND_PRIMARY } from "@const";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useTalentStore } from "@store";
import { MDBCard } from "mdb-react-ui-kit";
import { useEffect, useRef } from "react";
import { FiDownload } from "react-icons/fi";

const TalentCV = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const addMode = props.addMode;
  const submitChanges = props.submitChanges;

  const firstRender = useRef(true);

  useEffect(() => {
    if (state.status === "edit") return;
    if (firstRender.current) {
      firstRender.current = false;
      return;
    } else {
      submitChanges();
      firstRender.current = true;
    }
  }, [state.talent?.cv_file]);

  const removePathMetadata = (url: string) => {
    if (!url) return null;
    const name = url?.replace(/\\+/g, "/")?.split("/")[1]?.split("_") || [""];
    name?.shift();
    return name?.join("_");
  };

  return (
    <MDBCard className="resume-card d-flex flex-row justify-content-between px-4 py-2">
      <div className="d-flex align-items-center gap-2">
        <FiDownload />{" "}
        {state.talent?.cv_file_url ? (
          removePathMetadata(state.talent?.cv_file_url)
        ) : state.talent?.cv_file?.name ? (
          <i className="text-muted">{state.talent?.cv_file?.name}</i>
        ) : (
          "No cv uploaded"
        )}
      </div>
      <div>
        {state.talent?.cv_file_url ? (
          <Button size="small">
            <a
              href={`${process.env.REACT_APP_API_URL}/talents/cv/${state.talent?.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Download
            </a>
          </Button>
        ) : (
          ""
        )}
        {state.status !== "edit" || addMode ? (
          <LoadingButton
            size="small"
            loading={props.uploadLoading}
            variant="text"
            component="label"
            style={{ textTransform: "none", color: BEND_PRIMARY }}
          >
            Upload CV
            <input
              onChange={(e: any) => {
                if (e.target.files?.[0].type !== "application/pdf") {
                  alert("Please upload a pdf file");
                  return;
                }
                if (
                  window.confirm(
                    "File: " +
                      e.target.files?.[0].name +
                      " \nAre you sure you want to upload CV?"
                  )
                ) {
                  dispatch({
                    type: "SET_TALENT",
                    payload: {
                      ...state.talent,
                      cv_file: e.target.files?.[0],
                    },
                  });
                }
              }}
              type="file"
              hidden
            />
          </LoadingButton>
        ) : (
          ""
        )}
      </div>
    </MDBCard>
  );
};

export default TalentCV;
