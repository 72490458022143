import { Button } from "@mui/material";
import { useClientStore } from "@store";
import { useEffect } from "react";

const ClientHeader = (props: any) => {
  const { clientState: state, clientDispatch: dispatch } = useClientStore();

  const observer = new IntersectionObserver(
    ([e]) => e.target.toggleAttribute("stuck", e.intersectionRatio < 1),
    {
      threshold: [1],
    }
  );
  useEffect(() => {
    observer.observe(document.querySelector(".client-sticky")!);
  }, []);

  return (
    <div className="client-sticky">
      <div className="d-flex justify-content-between ">
        <Button
          className="client-back-button "
          size="small"
          onClick={props.navigateBack}
        >
          <div className="d-flex align-items-center">← Back to clients</div>
        </Button>
        <>
          <div className="d-flex flex-row align-items-center gap-4">
            {state.status !== "edit" ? (
              <Button
                className="text-nowrap background-bend-primary-color"
                variant="contained"
                onClick={() =>
                  dispatch({ type: "SET_STATUS", payload: "edit" })
                }
              >
                Edit client
              </Button>
            ) : (
              <div className="d-flex gap-2 flex-row">
                <Button
                  className="text-nowrap background-bend-primary-color"
                  variant="contained"
                  onClick={() => props.formRef.current?.requestSubmit()}
                >
                  Save
                </Button>
                <Button
                  className="text-nowrap background-bend-primary-color"
                  variant="contained"
                  onClick={() => {
                    dispatch({ type: "SET_STATUS", payload: "view" });
                    dispatch({
                      type: "SET_CLIENT",
                      payload: state.originalClient!,
                    });
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  );
};
export { ClientHeader };
