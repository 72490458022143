import {
  TalentPublicCTA,
  TalentPublicEducation,
  TalentPublicGeneralInfo,
  TalentPublicProjects,
  TalentPublicTechnologies,
} from "@components/talents";
import { BEND_PRIMARY } from "@const";
import { TalentPublicType } from "@interfaces";
import { Button } from "@mui/material";
import "@styles/public.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const TalentPublic = (props: any) => {
  const [errorMesage, setErrorMesage] = useState<string | null>(null);
  const { id } = useParams();
  const [token] = useSearchParams();
  const [talent, setTalent] = useState<TalentPublicType | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      talent
        ? setErrorMesage(null)
        : setErrorMesage("Something went wrong. Please try again later.");
    }, 10000);
    axios
      .get(`${process.env.REACT_APP_API_URL}/public/talents/${id!}`, {
        params: { t: token.get("t") },
        headers: {
          Authorization:
            "Bearer " +
            (sessionStorage.getItem("accessToken") ||
              localStorage.getItem("refreshToken")),
        },
      })
      .then((res) => {
        setTalent(res.data);
      })
      .catch((err) => {
        setErrorMesage(
          err?.message ||
            err?.response?.data?.message ||
            "Something went wrong. Please try again later. :("
        );
      });

    return () => {
      clearTimeout(timer);
    };
  }, [id, token, setTalent]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearForm = (
    setName: (name: string) => void,
    setEmail: (email: string) => void,
    setComment: (comment: string) => void
  ) => {
    setName("");
    setEmail("");
    setComment("");
  };

  const handleHire = (
    name: string,
    email: string,
    comment: string,
    setName: (name: string) => void,
    setEmail: (email: string) => void,
    setComment: (comment: string) => void
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/public/talents/hire/${id}?${token}`,
        {
          name,
          email,
          comment,
        }
      )
      .then((res) => {
        window.alert(
          "Thank you for your interest! We will contact you shortly."
        );
      })
      .catch((err) => {
        window.alert("Something went wrong. Please try again later.");
      })
      .finally(() => {
        clearForm(setName, setEmail, setComment);
        handleClose();
      });
  };

  return (
    <div>
      {talent ? (
        <div className=" public-container">
          <div className="public-content">
            <div className="public-general">
              <TalentPublicGeneralInfo talent={talent} />
              <TalentPublicTechnologies talent={talent} />
              <hr className="my-4 border border-1 border-dark" />
              {/* END of main */}
              <TalentPublicProjects talent={talent} />
              <hr className="my-4 border border-1 border-dark" />
              <TalentPublicEducation talent={talent} />
            </div>
            <div className="public-cta">
              <TalentPublicCTA />
            </div>
          </div>
          <Button
            className="hire-button-responsive mx-auto d-none"
            onClick={handleOpen}
            variant="contained"
            style={{ backgroundColor: BEND_PRIMARY }}
          >
            Check if {talent?.name.split(" ")[0]} is available
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          {errorMesage ? (
            <h2 className="text-danger">{errorMesage}</h2>
          ) : (
            <h2>Loading</h2>
          )}
        </div>
      )}
    </div>
  );
};

export { TalentPublic };
