import { TalentJobStatus } from "@enums";
import { Job, TalentJob } from "@interfaces";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import { useTalentsStore, useTalentStore } from "@store";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const TalentJobsForm = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const [selectedJob, setSelectedJob] = React.useState<Job | null>(null);
  const [status, setStatus] = React.useState<TalentJobStatus | null>(null);
  const { talent_job_statuses } = useTalentsStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [jobQuery, setJobQuery] = useState<string>("");

  const renderSelectMenu = () => {
    const items = talent_job_statuses?.map((option: any, index: number) => {
      return (
        <MenuItem className="text-capitalize" key={index} value={option}>
          {option}
        </MenuItem>
      );
    });
    return [
      <MenuItem
        className="text-capitalize"
        disabled
        hidden
        value={"null"}
      ></MenuItem>,
      ...(items || []),
    ];
  };

  const addJob = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/jobs/talents", {
        talent_id: state.talent?.id,
        job_id: selectedJob?.id,
        status: status,
      })
      .then((res) => {
        props.fetchTalent();
        props.setFormOpen(false);
        props.logTalentActivity("talents_jobs_insert_activity", {
          job_id: selectedJob?.id,
          status: status,
        });
      });
  };

  const isJobUnique = () => {
    const jobIds = state.talent?.jobs?.map((job: TalentJob) => job.job_id);
    return !jobIds?.includes(selectedJob?.id) || "Job already added!";
  };

  return (
    <>
      <form onSubmit={handleSubmit(addJob)}>
        <div className="talent-jobs-item  py-2">
          <div className="d-flex flex-column gap-3">
            <Autocomplete
              getOptionLabel={(option) => option.name}
              options={props.jobs}
              value={selectedJob}
              onChange={(event: any, newValue: any) => {
                setSelectedJob(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("talent_job", {
                    required: "Please select a job",
                    validate: isJobUnique,
                  })}
                  error={!!errors.talent_job}
                  helperText={<>{errors.talent_job?.message!}</>}
                  onChange={(e) => {
                    setJobQuery(e.target.value);
                  }}
                  label="Job"
                  size="small"
                  value={jobQuery}
                />
              )}
            />
            <div>
              <TextField
                {...register("talent_job_status", { required: true })}
                error={!!errors.talent_job_status}
                helperText={
                  errors.talent_job_status && "Please select a status"
                }
                fullWidth
                select
                label="Status"
                size="small"
                variant="outlined"
                value={status}
                onChange={(e: any) => {
                  setStatus(e.target.value);
                }}
              >
                {renderSelectMenu()}
              </TextField>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button type="submit">Submit</Button>
            <Button onClick={() => props.setFormOpen(false)}>Cancel</Button>
          </div>
        </div>
      </form>
    </>
  );
};

export { TalentJobsForm };
