import { TextLabel } from "@components/textlabel";
import { MenuItem, TextField } from "@mui/material";
import { useClientStore } from "@store";
import { wrapLinksInAnchorTags } from "@utils";
import parse from "html-react-parser";

type ClientTextFieldProps = {
  label: string;
  field: string[];
  select?: boolean;
  link?: boolean;
  options?: any[];
  required?: boolean;
  placeholder?: string[];
};

const ClientTextField = ({
  field,
  select,
  link,
  options,
  label,
  required,
  placeholder,
}: ClientTextFieldProps) => {
  const { clientState: state, clientDispatch: dispatch } = useClientStore();
  const fieldValues = field.map((f) => state.client?.[f]);
  const joinedFieldValue = fieldValues.filter(Boolean).join(", ");
  const {
    register,
    formState: { errors },
  } = state.form!;

  if (state.status !== "edit" && !joinedFieldValue) return null;

  const renderSelectMenu = () => {
    if (select) {
      const items = options?.map((option: any, index: number) => {
        return (
          <MenuItem className="text-capitalize" key={index} value={option}>
            {option}
          </MenuItem>
        );
      });
      return [
        <MenuItem
          className="text-capitalize"
          disabled
          hidden
          value={"null"}
        ></MenuItem>,
        ...(items || []),
      ];
    }
    return null;
  };

  return (
    <div>
      <TextLabel>{label}</TextLabel>
      <div className="d-flex flex-row flex-nowrap client-location">
        {state.status === "edit" ? (
          field.map((f, index) => (
            <TextField
              key={index}
              {...register(f, { required })}
              error={!!errors[f]}
              helperText={errors[f] ? "Required" : ""}
              style={{ height: "32px" }}
              className="w-100"
              select={select}
              placeholder={placeholder?.[index]}
              size="small"
              variant="outlined"
              value={state.client?.[f] || ""}
              onChange={(event: any) => {
                dispatch({
                  type: "SET_CLIENT",
                  payload: {
                    ...state.client,
                    [f]: event.target.value,
                  },
                });
              }}
            >
              {renderSelectMenu()}
            </TextField>
          ))
        ) : (
          <div className="py-1">
            {link
              ? parse(wrapLinksInAnchorTags(joinedFieldValue))
              : joinedFieldValue}
          </div>
        )}
      </div>
    </div>
  );
};

export { ClientTextField };
