import { Client } from "@interfaces";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import { Link } from "react-router-dom";

type TableProps = {
  clients: Client[];
  total: number;
};

const ClientsTable = <T extends {}>({ clients, total }: TableProps) => {
  const [page, setPage] = useState(0);
  const [take, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  function handleChangePage(event: any, newpage: number) {
    setPage(newpage);
  }

  function handleChangeRowsPerPage(event: any) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  return (
    <div className="table">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1400 }}>
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="ps-4 font-weight-bold column-header ">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => {
                      setSort("name");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                  >
                    Name
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell
                  className="font-weight-bold column-header "
                  align="left"
                >
                  <div
                    onClick={() => {
                      setSort("location");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    Location
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell
                  className="font-weight-bold column-header "
                  align="left"
                >
                  <div
                    onClick={() => {
                      setSort("jobs");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    Jobs
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            {clients ? (
              <TableBody>
                {clients?.map((client: Client, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="ps-4" component="th" scope="row">
                      <Link to={"/clients/" + client?.id!}>
                        {client?.name!}
                      </Link>
                      {dayjs(client?.created_at!) >
                      dayjs().subtract(7, "day") ? (
                        <span className="badge badge-secondary mx-4">New</span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell className="text-capitalize  ">
                      {`${client?.country!}, ${client?.city!}`}
                    </TableCell>
                    <TableCell className="text-capitalize ">
                      {client?.jobs?.length || 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {Array.from({ length: 7 }, (_, index) => {
                  return (
                    <TableRow key={index}>
                      {Array.from({ length: 7 }, (_, index) => {
                        return (
                          <TableCell
                            key={index}
                            align={
                              index > 1
                                ? index < 6
                                  ? "center"
                                  : "right"
                                : "left"
                            }
                          >
                            <svg height={30} width={188} className="skeleton">
                              <rect
                                x="5"
                                y="8"
                                rx="3"
                                ry="3"
                                width="180"
                                height="6"
                              />
                            </svg>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[1, 5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={take}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export { ClientsTable };
