import { Project, SelectedTechnology } from "@interfaces";
import { Chip } from "@mui/material";

const TalentPublicTechnologies = (props: any) => {
  const talent = props.talent;

  const getProjectTechnologyNames = () => {
    const techs = talent?.cv_projects
      ?.map((project: Project) => {
        return project?.technologies?.map((tech: string) => {
          return tech?.trim();
        });
      })
      .flat();
    return (
      techs?.filter((tech: string, index: number) => {
        return techs.indexOf(tech) === index;
      }) || []
    );
  };

  const getOtherTechNames = () => {
    return (
      talent?.cv_other_technologies?.map(
        (tech: SelectedTechnology, index: number) => {
          return tech.technology?.trim();
        }
      ) || []
    );
  };
  const getCoreTechNames = () => {
    return (
      talent?.cv_core_technologies?.map(
        (tech: SelectedTechnology, index: number) => {
          return tech.technology?.trim();
        }
      ) || []
    );
  };

  const combineTech = () => {
    const combinedTech = [
      ...getOtherTechNames()!,
      ...getProjectTechnologyNames()!,
    ];
    return combinedTech.filter((tech: string, index: number) => {
      return (
        combinedTech.indexOf(tech?.trim()) === index &&
        getCoreTechNames()?.indexOf(tech?.trim()) === -1
      );
    });
  };

  return (
    <>
      {talent?.cv_core_technologies?.every((el: any) => !!el) ? (
        <>
          <div className="tech-label">Core technologies</div>
          <div className="tech-container">
            {talent?.cv_core_technologies?.map(
              (tech: SelectedTechnology, index: number) => {
                return (
                  <Chip
                    key={index}
                    className="core-tech"
                    label={
                      <div className="core-tech">
                        <div className="">{tech.technology}</div>
                        {tech.experience_in_years ? (
                          <div>
                            {tech.experience_in_years}
                            {tech.experience_in_years > 1 ? " years" : " year"}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                  />
                );
              }
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {combineTech()?.every((el) => !!el) ? (
        <>
          <div className="tech-label">Other technologies</div>
          <div className="tech-container">
            {combineTech()?.map((tech: string, index: number) => {
              if (tech)
                return (
                  <Chip
                    key={index}
                    className="other-tech"
                    label={<div className="">{tech}</div>}
                  />
                );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export { TalentPublicTechnologies };
