import {
  TalentPublicEducation,
  TalentPublicGeneralInfo,
  TalentPublicProjects,
  TalentPublicTechnologies,
} from "@components/talents";
import { Talent } from "@interfaces";
import { useEffect, useState } from "react";
import "../../styles/pdf.css";

export const PdfPage = () => {
  const [talent, setTalent] = useState<Talent | null>(null);

  useEffect(() => {
    console.log("talent", localStorage.getItem("talent"));

    setTalent(JSON.parse(localStorage.getItem("talent")!));
  }, []);

  if (!localStorage.getItem("talent")) return null;

  return (
    <div className="pdf">
      <TalentPublicGeneralInfo talent={talent} />
      <TalentPublicTechnologies talent={talent} />
      <hr className="my-4 border border-1 border-dark" />
      <TalentPublicProjects talent={talent} />
      <hr className="my-4 border border-1 border-dark" />
      <TalentPublicEducation talent={talent} />
    </div>
  );
};
