// StoreProvider.js

import { TalentJobStatus } from "@enums";
import { Status, Technology } from "@interfaces";
import axios from "axios";
import React, { useEffect, useState } from "react";

type TalentStore = {
  status: Status[];
  technologies: Technology[];
  roles: string[];
  types: string[];
  seniorities: string[];
  languageLevels: string[];
  talent_job_statuses: TalentJobStatus[];
};

const TalentContext = React.createContext<TalentStore | null>(null);

export const useTalentsStore = () => {
  const context = React.useContext(TalentContext);
  if (!context) {
    throw new Error(
      "useTalentsStore must be used within a TalentsStoreProvider"
    );
  }
  return context;
};

const TalentsStoreProvider = ({ children }: any) => {
  const [status, setStatus] = useState<Status[]>([]);
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [seniorities, setSeniorities] = useState<string[]>([]);
  const [languageLevels, setLanguageLevels] = useState<string[]>([]);
  const [talent_job_statuses, setTalentJobStatuses] = useState<
    TalentJobStatus[]
  >([]);

  useEffect(() => {
    const getTalentContext = async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/talents/context`)
        .then((res) => {
          setStatus(res.data.talentStatuses);
          setTechnologies(res.data.talentTechnologies);
          setRoles(res.data.talentRoles);
          setTypes(res.data.talentTypes);
          setSeniorities(res.data.talentSeniorities);
          setLanguageLevels(res.data.talentLanguageLevels);
          setTalentJobStatuses(res.data.talent_job_statuses);
        });
    };
    getTalentContext();
  }, []);

  const store = {
    status,
    technologies,
    roles,
    types,
    seniorities,
    languageLevels,
    talent_job_statuses,
  };

  return (
    <TalentContext.Provider value={store}>{children}</TalentContext.Provider>
  );
};

export { TalentsStoreProvider, TalentContext };
