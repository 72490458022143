import { Job, Project, TalentJob } from "@interfaces";
import dayjs from "dayjs";

export function sortByDate<T>(objects: T[]): T[] {
  return objects.sort((a: T | any, b: T | any) => {
    if (a?.ongoing && !b?.ongoing) {
      return -1;
    } else if (!a?.ongoing && b?.ongoing) {
      return 1;
    } else if (a?.ongoing && b?.ongoing) {
      return dayjs(b.from).diff(dayjs(a.from));
    } else {
      return dayjs(b.to).diff(dayjs(a.to));
    }
  });
}

export const groupProjectsByCompany = (projects: Project[]): any => {
  const sortedProjects = sortByDate<Project>(projects);
  let groupedProjects: Project[][] = [];
  let currentGroup: Project[] = [];
  let currentCompany = "";
  sortedProjects.forEach((project: Project) => {
    if (project.company_name !== currentCompany) {
      if (currentGroup.length > 0) {
        groupedProjects.push(currentGroup);
      }
      currentGroup = [];
      currentCompany = project.company_name;
    }
    currentGroup.push(project);
  });
  if (currentGroup.length > 0) {
    groupedProjects.push(currentGroup);
  }
  return groupedProjects;
};

export const groupJobsByStatus = (jobs: Job[]): any => {
  const groupedJobs = jobs.reduce((acc: any, job: Job) => {
    if (!acc[job.status]) {
      acc[job.status] = [];
    }
    acc[job.status].push(job);
    return acc;
  }, {});

  return groupedJobs;
};

export const calculateOtherJobsCount = (
  jobs: TalentJob[],
  current_job_id: number
): number => {
  const otherJobs = jobs.filter(
    (job: TalentJob) => job.job_id !== current_job_id
  );

  return otherJobs.length;
};
