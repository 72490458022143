import { defaultJob } from "@const";
import {
  EmptyJob,
  Job,
  JobContextEnums,
  JobState,
  JobStoreStatus,
} from "@interfaces";
import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";

type Action =
  | { type: "SET_JOB"; payload: Job | EmptyJob }
  | { type: "SET_STATUS"; payload: JobStoreStatus }
  | { type: "SET_ORIGINAL_JOB"; payload: Job | EmptyJob }
  | { type: "SET_CONTEXT"; payload: JobContextEnums };

const reducer = (state: JobState, action: Action): JobState => {
  switch (action.type) {
    case "SET_JOB":
      return { ...state, job: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_ORIGINAL_JOB":
      return {
        ...state,
        originalJob: action.payload,
        job: action.payload,
      };
    case "SET_CONTEXT":
      return { ...state, context: action.payload };

    default:
      return state;
  }
};

export const JobStoreContext = createContext<{
  jobState: JobState;
  jobDispatch: React.Dispatch<Action>;
}>({
  jobState: {
    job: null,
    originalJob: null,
    status: "view",
    form: null,
    context: null,
  },
  jobDispatch: () => {},
});

export const useJobStore = () => {
  const context = useContext(JobStoreContext);
  if (context === undefined) {
    throw new Error("useJobStore must be used within a JobStoreProvider");
  }
  return context;
};

export const JobStoreProvider: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, {
    originalJob: defaultJob,
    job: defaultJob,
    status: "pending",
    form: useForm(),
    context: null,
  });

  useEffect(() => {
    const getJobContext = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/jobs/context`).then((res) => {
        dispatch({ type: "SET_CONTEXT", payload: res.data });
      });
    };
    getJobContext();
  }, []);

  return (
    <JobStoreContext.Provider
      value={{
        jobState: state,
        jobDispatch: dispatch,
      }}
    >
      <Outlet />
    </JobStoreContext.Provider>
  );
};
