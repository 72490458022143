import { TalentCV, TalentRTE, TalentTextField } from "@components/talents";
import { useTalentsStore, useTalentStore } from "@store";
import { MDBCard } from "mdb-react-ui-kit";
import { forwardRef } from "react";

const TalentGeneralInfoPublic = forwardRef((props: any, ref: any) => {
  const { state, dispatch } = useTalentStore();
  const { languageLevels } = useTalentsStore();

  if (
    !state.talent?.english_level &&
    !state.talent?.cv_short_description &&
    !state.talent?.cv_detailed_description &&
    !state.talent?.country &&
    state.status !== "edit"
  )
    return null;
  return (
    <MDBCard className="general-info ">
      <div className=" font-weight-bold general-info-grid">
        <TalentTextField
          field="english_level"
          label="English level"
          select
          options={languageLevels}
          internal={false}
        />
        <TalentTextField field="country" label="Country" internal={false} />
      </div>
      <TalentRTE
        field="cv_short_description"
        label="Short Description"
        internal={false}
      />

      <TalentRTE
        field="cv_detailed_description"
        label="Detailed Description"
        internal={false}
        collapsable={true}
      />
    </MDBCard>
  );
});
export { TalentGeneralInfoPublic };
