import { SelectedTechnology, TechnologyFormType } from "@interfaces";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { useTalentsStore, useTalentStore } from "@store";
import { IoRemoveCircleOutline } from "react-icons/io5";

const TalentTechnologyForm = ({
  technology,
  experience,
  index,
  form,

  handleTechnologyDelete,
}: TechnologyFormType) => {
  const { technologies } = useTalentsStore();
  const { state, dispatch } = useTalentStore();
  const {
    register,
    formState: { errors },
  } = state.form!;

  const technologyArrayKey =
    form === "core" ? "cv_core_technologies" : "cv_other_technologies";

  return (
    <div>
      {state.status === "edit" ? (
        <div className="talent-skill-form">
          <IconButton
            className="p-0"
            size="small"
            onClick={(e: any) => {
              handleTechnologyDelete(e, index, form);
              // submitChanges();
            }}
          >
            <IoRemoveCircleOutline />
          </IconButton>
          <Autocomplete
            size="small"
            //select all techonoly keys from technologies array
            options={technologies.map((tech) => tech.technology)}
            // options={technologies}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              dispatch({
                type: "SET_TALENT",
                payload: {
                  ...state.talent,
                  [technologyArrayKey]: (
                    state.talent?.[technologyArrayKey] as SelectedTechnology[]
                  )?.map((tech, i) =>
                    i === index
                      ? {
                          ...tech,
                          technology: newValue,
                        }
                      : tech
                  ),
                },
              });
            }}
            value={technology}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                {...register("technology" + form + index, {
                  required: true,
                  value: technology,
                })}
                onChange={(event) => {
                  dispatch({
                    type: "SET_TALENT",
                    payload: {
                      ...state.talent,
                      [technologyArrayKey]: (
                        state.talent?.[
                          technologyArrayKey
                        ] as SelectedTechnology[]
                      )?.map((tech, i) =>
                        i === index
                          ? {
                              ...tech,
                              technology: event.target.value,
                            }
                          : tech
                      ),
                    },
                  });
                }}
                placeholder="*Select technology"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                variant="outlined"
                error={!!errors["technology" + form + index]}
                helperText={
                  errors["technology" + form + index] &&
                  "Technology is required"
                }
              />
            )}
          />
          <TextField
            size="small"
            onChange={(event: any) => {
              dispatch({
                type: "SET_TALENT",
                payload: {
                  ...state.talent,
                  [technologyArrayKey]: (
                    state.talent?.[technologyArrayKey] as SelectedTechnology[]
                  )?.map((tech, i) =>
                    i === index
                      ? {
                          ...tech,
                          experience_in_years: event.target.value,
                        }
                      : tech
                  ),
                },
              });
            }}
            value={experience}
            required
          />
        </div>
      ) : (
        <div className="d-flex flex-row gap-4 justify-content-between px-4">
          <div>{technology}</div>
          {experience ? <div>{experience} year(s)</div> : ""}
        </div>
      )}
    </div>
  );
};
export default TalentTechnologyForm;
