import { BEND_PRIMARY } from "@const";
import {
  CommentEvent,
  StatusChangeEvent,
  TalentActivity,
  TalentJob,
  TalentJobInsertEvent,
  TalentJobUpdateEvent,
} from "@interfaces";
import { useTalentsStore, useTalentStore } from "@store";
import { smartDate } from "@utils";
import dayjs from "dayjs";
import React from "react";

export const TalentEventItem: React.FC<{
  event: TalentActivity;
}> = ({ event }) => {
  const { status } = useTalentsStore();
  const { state, dispatch } = useTalentStore();
  const eventRenderers: Record<Event["type"], React.ReactNode> = {
    comment_activity: (
      <div className="talent-history-comment">
        <div
          className="d-inline  font-weight-bold "
          style={{ color: BEND_PRIMARY }}
        >
          {event?.created_by}
        </div>
        {` added a comment - ${smartDate(dayjs(event?.created_at))}`}
        <div
          dangerouslySetInnerHTML={{
            __html: (event as CommentEvent)?.activity?.comment,
          }}
        />
      </div>
    ),
    status_activity: (
      <div className="talent-history-status">
        <div
          className="d-inline  font-weight-bold "
          style={{ color: BEND_PRIMARY }}
        >
          {event?.created_by}
        </div>{" "}
        changed status{" "}
        <div className="d-inline font-weight-bold text-muted">
          {
            status.find(
              (s) => s.id === (event as StatusChangeEvent)?.activity?.old_status
            )?.status
          }
        </div>{" "}
        to{" "}
        <div className="d-inline font-weight-bold text-muted">
          {
            status.find(
              (s) => s.id === (event as StatusChangeEvent)?.activity?.new_status
            )?.status
          }
        </div>{" "}
        - {smartDate(dayjs(event?.created_at))}
      </div>
    ),
    talents_jobs_insert_activity: (
      <div className="talent-history-status">
        <div
          className="d-inline  font-weight-bold "
          style={{ color: BEND_PRIMARY }}
        >
          {event?.created_by}
        </div>{" "}
        added{" "}
        <div className="d-inline font-weight-bold text-muted">
          {
            state.talent?.jobs.find(
              (job: TalentJob) =>
                job.job_id === (event as TalentJobInsertEvent)?.activity?.job_id
            )?.job?.name
          }
        </div>{" "}
        job with status{" "}
        <div className="d-inline font-weight-bold text-muted">
          {(event as TalentJobInsertEvent)?.activity?.status}
        </div>{" "}
        - {smartDate(dayjs(event?.created_at))}
      </div>
    ),
    talents_jobs_update_activity: (
      <div className="talent-history-status">
        <div
          className="d-inline  font-weight-bold"
          style={{ color: BEND_PRIMARY }}
        >
          {event?.created_by}
        </div>{" "}
        changed status from{" "}
        <div className="d-inline font-weight-bold text-muted">
          {(event as TalentJobUpdateEvent)?.activity.old_status}
        </div>{" "}
        to{" "}
        <div className="d-inline font-weight-bold text-muted">
          {(event as TalentJobUpdateEvent)?.activity.new_status}
        </div>{" "}
        for{" "}
        <div className="d-inline font-weight-bold text-muted">
          {
            state.talent?.jobs.find(
              (job: TalentJob) =>
                job.job_id === (event as TalentJobInsertEvent)?.activity?.job_id
            )?.job?.name
          }
        </div>{" "}
        job{" "}
        <div className="d-inline font-weight-bold text-muted">
          {(event as TalentJobInsertEvent)?.activity?.status}
        </div>{" "}
        - {smartDate(dayjs(event?.created_at))}
      </div>
    ),
  };

  const Renderer = eventRenderers[event.type];

  if (!Renderer) {
    throw new Error(`Unsupported event type: ${event.type}`);
  }

  return <>{Renderer}</>;
};
