import { useAuthStore } from "@store";
import axios from "axios";
import jwt from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const { auth, setAuth } = useAuthStore();

  const navigate = useNavigate();

  type accessToken = {
    username: string;
    role: { id: number; name: string };
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { username, password } = e.target.elements;
    axios
      .post(`${process.env.REACT_APP_API_URL}/signin`, {
        username: username.value,
        password: password.value,
      })

      .then((response) => {
        const decodedToken = jwt<accessToken>(response.data.accessToken);
        setAuth({
          ...auth,
          isAuth: true,
          username: decodedToken.username,
          role: decodedToken.role.name,
        });
        sessionStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        navigate(`/talents`);
      })
      .catch((error) => {
        setErrorMessage(
          Array.isArray(error.response.data.message)
            ? error.response.data.message
            : [error.response.data.message]
        );
      });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          clear: "both",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder="Enter your username"
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              id="examplePassword"
              placeholder="Enter your password"
            />
          </FormGroup>
          <Button style={{ cursor: "pointer" }} color="primary">
            Sign In
          </Button>
        </Form>
      </div>

      <div
        style={{
          color: "red",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul style={{ marginTop: "30px" }}>
          {errorMessage?.map((message: string) => (
            <li>{message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { SignIn };
