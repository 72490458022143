import { TalentJob } from "@interfaces";
import { MenuItem, TextField } from "@mui/material";
import { useTalentsStore, useTalentStore } from "@store";
import { useEffect, useRef, useState } from "react";

type TalentTextFieldProps = {
  talentJob: TalentJob;
  logTalentActivity: (type: string, activity: any) => void;
  submitStatusChanges: (
    talentJob: Omit<TalentJob, "id" | "created_at" | "talent" | "job">
  ) => void;
};

const TalentJobStatusTextField = ({
  talentJob,
  submitStatusChanges,
  logTalentActivity,
}: TalentTextFieldProps) => {
  const { state, dispatch } = useTalentStore();
  const { talent_job_statuses } = useTalentsStore();
  const [status, setStatus] = useState<string>(talentJob.status);
  // const {
  //   register,
  //   formState: { errors },
  // } = state.form!;
  const firstRender = useRef(true);

  const renderSelectMenu = () => {
    const items = talent_job_statuses?.map((option: any, index: number) => {
      return (
        <MenuItem className="text-capitalize" key={index} value={option}>
          {option}
        </MenuItem>
      );
    });
    return [
      <MenuItem
        className="text-capitalize"
        disabled
        hidden
        value={"null"}
      ></MenuItem>,
      ...(items || []),
    ];
  };

  useEffect(() => {
    if (status) {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      } else {
        submitStatusChanges({
          job_id: talentJob.job_id,
          status: status,
          talent_id: talentJob.talent_id,
        });
        logTalentActivity("talents_jobs_update_activity", {
          job_id: talentJob.job_id,
          old_status: talentJob.status,
          new_status: status,
        });
      }
    }
  }, [status]);

  if (!talent_job_statuses.length) return null;

  return (
    <div className="py-2">
      <TextField
        // {...register(field, { required })}
        // error={!!errors[field]}
        // helperText={errors[field] ? "Required" : ""}
        label="Status"
        fullWidth
        select
        size="small"
        variant="outlined"
        value={status}
        onChange={(e: any) => {
          setStatus(e.target.value);
        }}
      >
        {renderSelectMenu()}
      </TextField>
    </div>
  );
};
export { TalentJobStatusTextField };
