import { TextLabel } from "@components/textlabel";
import { Autocomplete, Chip, List, TextField } from "@mui/material";
import { useTalentsStore, useTalentStore } from "@store";

const TalentExperienceTechnologies = (props: any) => {
  const { technologies } = useTalentsStore();
  const index = props.index;
  const { state, dispatch } = useTalentStore();
  if (
    !state.talent?.cv_projects[index]?.technologies?.length &&
    state.status !== "edit"
  )
    return null;
  return (
    <div>
      <TextLabel>Technologies</TextLabel>
      {state.status === "edit" ? (
        <Autocomplete
          multiple
          limitTags={8}
          options={technologies?.map((option) => option.technology)}
          freeSolo
          value={state.talent?.cv_projects[index].technologies || []}
          onChange={(e: any, value: string[]) => {
            //split values by comma
            const values = value
              .join(",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v !== "");

            dispatch({
              type: "SET_TALENT",
              payload: {
                ...state.talent,
                cv_projects: state.talent?.cv_projects?.map(
                  (project: any, i: number) =>
                    i === index
                      ? {
                          ...project,
                          technologies: values,
                        }
                      : project
                ),
              },
            });
          }}
          renderTags={(value: readonly string[], getTagProps: any) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="filled"
                label={option!}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} size="small" variant="outlined" />
          )}
        />
      ) : (
        <>
          {state.talent?.cv_projects[index]?.technologies?.length ? (
            <List className="d-flex justify-content-start align-content-between flex-wrap py-0">
              {state.talent?.cv_projects[index]?.technologies?.map(
                (data: string, index: number) => {
                  return (
                    <Chip
                      key={index}
                      className="mr-2 mb-1 talent-project-tech"
                      label={data}
                    />
                  );
                }
              )}
            </List>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
export default TalentExperienceTechnologies;
