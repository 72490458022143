import { BEND_PRIMARY } from "@const";
import { Talent } from "@interfaces";
import { Chip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useFilterContext } from "@store";
import "@styles/talents.css";
import { smartDate } from "@utils";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import { Link } from "react-router-dom";

const TalentsTable = (props: any) => {
  const {
    state: { items: talents, filters: filters },
    dispatch,
  } = useFilterContext();
  const total = props.total;
  const [page, setPage] = useState(filters?.page || 0);
  const [take, setRowsPerPage] = useState(filters?.take || 10);
  const [sort, setSort] = useState(filters?.sort || "");
  const [order, setOrder] = useState(filters?.order || "");

  function handleChangePage(event: any, newpage: number) {
    setPage(newpage);
  }

  function handleChangeRowsPerPage(event: any) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  useEffect(() => {
    setPage(filters?.page || 0);
    setRowsPerPage(filters?.take || 10);
  }, [filters?.page, filters?.take]);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, page, take, sort, order },
    });
  }, [page, take, sort, order, dispatch]);

  return (
    <div className="talents-table">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="header-row">
                <TableCell className="ps-4 font-weight-bold column-header ">
                  <div
                    onClick={() => {
                      setSort("name");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center "
                  >
                    Name
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell
                  className="font-weight-bold column-header "
                  align="left"
                >
                  <div
                    onClick={() => {
                      setSort("status");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    Status
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell
                  className="font-weight-bold column-header "
                  align="left"
                >
                  <div
                    onClick={() => {
                      setSort("role");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    Role
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell className="font-weight-bold column-header">
                  <div className="d-flex justify-content-between align-items-center text-nowrap">
                    Core Tech
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell className="font-weight-bold column-header">
                  <div
                    onClick={() => {
                      setSort("seniority");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    Seniority
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell className="font-weight-bold column-header">
                  <div
                    onClick={() => {
                      setSort("english_level");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    English level
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
                <TableCell
                  className="font-weight-bold text-bold column-header"
                  align="right"
                >
                  <div
                    onClick={() => {
                      setSort("updated_at");
                      setOrder(order === "asc" ? "desc" : "asc");
                    }}
                    className="d-flex justify-content-end align-items-center text-right"
                  >
                    Last activity
                    <TiArrowUnsorted size={"15px"} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            {talents.length ? (
              <TableBody>
                {talents?.map((talent: Talent, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="ps-4 text-nowrap " component="th">
                      <Link to={"/talents/" + talent?.id!}>
                        {talent?.name!}
                      </Link>
                      {dayjs(talent?.created_at!) >
                      dayjs().subtract(7, "day") ? (
                        <span className="badge badge-secondary ms-4">New</span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell className="text-capitalize " align="center">
                      {talent?.status?.status!}
                    </TableCell>
                    <TableCell className="text-capitalize " align="center">
                      {talent?.role}
                    </TableCell>
                    <TableCell className="core-tech-cell" align="center">
                      <div className="core-tech-list">
                        {talent.cv_core_technologies
                          .slice(0, 2)
                          .map((data: any, index: number) => {
                            return (
                              <Chip
                                key={index}
                                className="talents-core-tech"
                                label={data?.technology}
                              />
                            );
                          })}
                        {talent.cv_core_technologies.length > 2 && (
                          <Chip
                            className="talents-core-tech"
                            label={`+${talent.cv_core_technologies.length - 2}`}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="text-capitalize " align="center">
                      {talent?.seniority!}
                    </TableCell>
                    <TableCell className="text-capitalize " align="center">
                      {talent?.english_level!}
                    </TableCell>
                    <TableCell
                      className="text-capitalize"
                      align="right"
                      sx={{ color: BEND_PRIMARY }}
                    >
                      {smartDate(dayjs(talent?.updated_at!))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {Array.from({ length: 7 }, (_, index) => {
                  return (
                    <TableRow key={index}>
                      {Array.from({ length: 7 }, (_, i: number) => {
                        return (
                          <TableCell
                            key={index + "" + i}
                            className={
                              i > 5 ? "d-flex justify-content-end" : ""
                            }
                          >
                            <svg
                              height={60}
                              width={120}
                              className={"skeleton d-flex"}
                            >
                              <rect
                                x="5"
                                y="27"
                                rx="3"
                                ry="3"
                                width="120"
                                height="6"
                              />
                            </svg>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[1, 5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={take}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default TalentsTable;
