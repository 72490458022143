import { IPropsSelectGroup, Technology } from "@interfaces";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { useTalentsStore } from "@store";
import { FC } from "react";

const FilterInput: FC<IPropsSelectGroup> = ({ filters, onFilterChange }) => {
  const {
    types,
    seniorities,
    status: statuses,
    languageLevels,
    technologies: technologiesList,
  } = useTalentsStore();

  let query = filters?.query || "";
  let type = filters?.type || "";
  let seniority = filters?.seniority || "";
  let english = filters?.english || "";
  let status = filters?.status || null;
  let technologies = filters?.technologies || [];
  let sort = filters?.sort || "";
  let order = filters?.order || "";
  let rejected = filters?.rejected || false;
  let { creation_method } = filters ?? {};

  return (
    <div className="talents-filters mb-2">
      <div className="talents-filter ">
        <TextField
          size="small"
          className="d-flex w-100"
          label="Search"
          value={query || ""}
          onChange={(event) => {
            onFilterChange({ key: "query", value: event.target.value });
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: query ? "visible" : "hidden" }}
                onClick={() => onFilterChange({ key: "query", value: "" })}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <Autocomplete
          size="small"
          options={types}
          value={type || null}
          isOptionEqualToValue={(option, value) => {
            return option === value;
          }}
          onChange={(event, value) =>
            onFilterChange({ key: "type", value: value })
          }
          renderInput={(params) => (
            <TextField {...params} label="Type" variant="outlined" />
          )}
        ></Autocomplete>
        <Autocomplete
          size="small"
          options={seniorities}
          isOptionEqualToValue={(option, value) => {
            return option === value;
          }}
          value={seniority || null}
          onChange={(event, value) =>
            onFilterChange({
              key: "seniority",
              value: value,
            })
          }
          renderInput={(params) => (
            <TextField {...params} label="Seniority" variant="outlined" />
          )}
        />
        <Autocomplete
          size="small"
          clearOnBlur={true}
          options={statuses}
          groupBy={(option: any) => option?.type}
          getOptionLabel={(option: any) => `${option.status}`}
          value={statuses.find((option: any) => option.id === status) || null}
          isOptionEqualToValue={(option, newValue) => {
            return option.id === newValue.id;
          }}
          onChange={(event, value: any) => {
            onFilterChange({ key: "status", value: value?.id });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Select status" />
          )}
        />
        <Autocomplete
          size="small"
          options={languageLevels}
          isOptionEqualToValue={(option, value) => option === value}
          value={english || null}
          onChange={(event, value) =>
            onFilterChange({
              key: "english",
              value: value?.length ? value : null,
            })
          }
          renderInput={(params) => (
            <TextField {...params} label="English level" variant="outlined" />
          )}
        ></Autocomplete>
        <Autocomplete
          size="small"
          options={['Manual', 'Imported'] as NonNullable<IPropsSelectGroup['filters']>['creation_method'][]}
          isOptionEqualToValue={(option, value) => option === value}
          value={creation_method ?? null}
          onChange={(event, value) =>
            onFilterChange({
              key: "creation_method",
              value: value ?? undefined,
            })
          }
          renderInput={(params) => (
            <TextField {...params} label="Creation method" variant="outlined" />
          )}
        ></Autocomplete>
        <Autocomplete
          size="small"
          multiple
          options={technologiesList?.map(
            (option: Technology) => option.technology
          )}
          freeSolo
          value={technologies || []}
          onChange={(event, value) =>
            onFilterChange({
              key: "technologies",
              value: value?.length ? value : null,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select technologies"
            />
          )}
          renderTags={(value, getTagProps) => (
            <div className="tech-selector-container">
              {value.length} selected
            </div>
          )}
        />
      </div>
      <div
        onClick={() => onFilterChange({ key: "rejected", value: !rejected })}
        className="div-button text-black d-flex align-items-center gap-1"
      >
        <input
          type="checkbox"
          checked={rejected || false}
          onChange={() => {
            onFilterChange({ key: "rejected", value: !rejected });
          }}
        ></input>
        <div className=" text-nowrap">Show rejected</div>
      </div>
    </div>
  );
};
export default FilterInput;
