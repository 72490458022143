import { EmptyTalent } from "@interfaces";

export const defaultTalent: EmptyTalent = {
  name: "",
  email: null,
  phone: null,
  country: null,
  english_level: null,
  cv_short_description: null,
  linked_in_url: null,
  type: "",
  seniority: null,
  cv_experience: null,
  cv_detailed_description: null,
  cv_core_technologies: [],
  cv_other_technologies: [],
  cv_languages: [],
  cv_projects: [],
  role: null,
  cv_education: [],
  status: null,
  comment: null,
  created_at: null,
  updated_at: null,
};
