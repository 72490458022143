import { BEND_PRIMARY } from "@const";
import { SelectedLanguage } from "@interfaces";
import { wrapLinksInAnchorTags } from "@utils";
import { IoLanguage, IoLocationSharp } from "react-icons/io5";

const TalentPublicGeneralInfo = (props: any) => {
  const talent = props.talent;

  return (
    <>
      <div className="d-flex align-items-center w-100 mb-2 public-name-info">
        <div className="public-name">
          <h3 className="font-weight-bold me-2">{talent?.name}</h3>
        </div>
        {talent?.country ? (
          <div className="d-flex ms-4 align-middle public-location">
            <IoLocationSharp size={"1.4em"} />
            <h6 className="text-muted me-4">{talent?.country}</h6>
          </div>
        ) : (
          ""
        )}
        <div className="break-flex d-none"></div>

        {talent?.english_level || talent?.cv_languages?.length ? (
          <div className="d-flex me-4 align-middle public-language">
            <IoLanguage size={"1.5em"} className="me-2" />
            <h6 className="text-muted ">
              {talent?.english_level ? "English " : null}
              {[talent?.english_level]
                ?.concat(
                  talent?.cv_languages?.map(
                    (lang: SelectedLanguage) => lang.language
                  )
                )
                ?.join(", ")}
            </h6>
          </div>
        ) : (
          ""
        )}
        {/* <div className="ml-auto">
                <Button
                  className="hire-button"
                  onClick={handleOpen}
                  variant="contained"
                  style={{ backgroundColor: BEND_PRIMARY }}
                >
                  Check if {talent?.name.split(" ")[0]} is available
                </Button>
                <ContactModal
                  open={open}
                  handleClose={handleClose}
                  fname={talent?.name}
                  handleHire={handleHire}
                />
              </div> */}
      </div>
      <h5
        className="d-block mb-4"
        style={{ color: BEND_PRIMARY }}
        dangerouslySetInnerHTML={{
          __html: wrapLinksInAnchorTags(talent?.cv_short_description),
        }}
      ></h5>
      <h6
        className="d-block"
        dangerouslySetInnerHTML={{
          __html: wrapLinksInAnchorTags(talent?.cv_detailed_description),
        }}
      />
    </>
  );
};
export { TalentPublicGeneralInfo };
